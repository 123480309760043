import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionIconChevronBottom, ActionIconChevronRight, ButtonQuaternary, DesignTokens, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Flex, useGetToken, useWidth } from '@klarna/mp-ui';
import { useFeature } from '@merchant-portal/experimentation';
import { StoreSelector, useLocalStorage } from 'mage-components';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import Insights from 'mage-insights';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { CurrencySelector, LastUpdatedWidget, SalesThisWeekWidget, WeekDayNavigator } from './components';
import { getDefaultCurrencyForTimezone, getTimezone, insightsDateUtils } from './model';
const trackMidSelectorChange = () => {
    return Insights.event({
        category: 'homepageContent:salesWidget',
        action: 'change_mid'
    });
};
const RoundedContainer = styled.div(() => {
    const borderColor = useGetToken('colors/borders/subtle-floating').toString();
    const borderRadius = useGetToken('corner-radius/m').value();
    return css({
        border: `1px solid ${borderColor}`,
        borderRadius
    });
});
const InnerContainer = styled.div(() => {
    const padding = useGetToken('space/300').value();
    return css({
        padding
    });
});
const LinkStyled = styled.a(() => {
    const backgroundColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    const bottomRadius = useGetToken('corner-radius/m').value();
    const padding = useGetToken('space/300').value();
    const designContext = DesignTokens.useDesignContext();
    const hoverBackgroundColor = designContext.get('colors/backgrounds/subtle-inline', {
        interactiveState: 'hovered'
    });
    return css({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding,
        backgroundColor,
        borderBottomRightRadius: bottomRadius,
        borderBottomLeftRadius: bottomRadius,
        textDecoration: 'none',
        ':hover': {
            backgroundColor: hoverBackgroundColor
        }
    });
});
const OrdersLink = ({ label }) => {
    const trackClickOnLink = () => Insights.event({
        category: 'homepageContent:salesWidget',
        action: 'go_to',
        label: 'Orders'
    });
    return (_jsxs(LinkStyled, { href: "/orders", onClick: trackClickOnLink, children: [_jsx(Typography, { textToken: "text-style/text/labels/body/medium", children: label }), _jsx(ActionIconChevronRight, {})] }));
};
OrdersLink.propTypes = {
    label: PropTypes.string.isRequired
};
export const SalesThisWeek = () => {
    const tokenHelper = coreHooks.useTokenHelper();
    const hasAccessToOrders = tokenHelper.hasClient('orders-fe');
    const salesExperiment = useFeature('mpux.sales-widget');
    const getMerchantDataByMids = useSelector(coreSelectors.getMerchantsByMids);
    const [isWidgetActive, setIsWidgetActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedMids, setSelectedMids] = useState([]);
    const [date, setDate] = useState(() => new Date().valueOf());
    const [weekNavigatorDate, setWeekNavigatorDate] = useState(() => new Date().valueOf());
    const [queryParams, setQueryParams] = useState({});
    const [singleStoreName, setSingleStoreName] = useState('');
    const timezone = getTimezone();
    const [currency, setCurrency] = useState(() => getDefaultCurrencyForTimezone({ timezone }));
    const STORAGE_KEY = 'sales-widget-preferred-currency';
    useLocalStorage(STORAGE_KEY, currency, setCurrency);
    const t = i18nHooks.useTranslator();
    const [ref, width] = useWidth();
    const isWide = width > 600;
    const mids = useMemo(() => tokenHelper.getMidsForClient('orders-fe'), [tokenHelper]);
    useEffect(() => {
        if (mids.length === 1) {
            const merchantData = getMerchantDataByMids(mids[0]);
            if (merchantData && merchantData.length > 0) {
                setSingleStoreName(merchantData[0].storeName);
            }
        }
    }, [mids, getMerchantDataByMids]);
    useEffect(() => {
        const isWidgetExperimentActive = R.propOr(false, 'is_enabled', salesExperiment);
        const disabledMids = R.propOr([], 'disabled_mids', salesExperiment);
        setIsWidgetActive(hasAccessToOrders && isWidgetExperimentActive && R.isEmpty(R.intersection(mids, disabledMids)));
    }, [salesExperiment, hasAccessToOrders, mids]);
    // Debounce date navigator
    useEffect(() => {
        const timer = setTimeout(() => {
            setDate(weekNavigatorDate);
        }, 100);
        return () => clearTimeout(timer);
    }, [weekNavigatorDate]);
    useEffect(() => {
        setQueryParams({
            merchant_id: selectedMids.length ? selectedMids.join(',') : mids.join(','),
            currency: currency,
            created_at_start: insightsDateUtils.getStartOfDay(date),
            created_at_end: insightsDateUtils.getEndOfDay(date)
        });
        // Need to set loading to false after the event loop ticks so it happens after the widget has set it's own loading
        // FIXME: setImmediate is deprecated - https://developer.mozilla.org/en-US/docs/Web/API/Window/setImmediate
        if ('setImmediate' in window) {
            setImmediate(() => setLoading(false));
        }
    }, [date, selectedMids, mids, currency]);
    const handleChangeWeekNavigatorDate = date => {
        // Need to set loading to true here so we display "-" before the debouncing is finished
        setLoading(true);
        setWeekNavigatorDate(date);
    };
    const handleChangeCurrency = currency => {
        // Need to set loading to true here so we display "-" before the widget takes over
        setLoading(true);
        setCurrency(currency);
    };
    if (!isWidgetActive) {
        return null;
    }
    return (_jsxs(RoundedContainer, { ref: ref, children: [_jsxs(InnerContainer, { children: [_jsxs(Flex, { justifyContent: "space-between", spacing: "space/200", column: !isWide, children: [_jsx(WeekDayNavigator, { date: weekNavigatorDate, isWide: isWide, onChange: handleChangeWeekNavigatorDate }), _jsxs(Flex, { spacing: "space/100", alignItems: "center", children: [mids.length === 1 && _jsx(SingleStoreLabel, { storeName: singleStoreName, mid: mids[0] }), _jsx(CurrencySelector, { currency: currency, onChange: handleChangeCurrency }), mids.length > 1 && (_jsx(StoreSelector, { isMultiselect: true, mids: mids, values: selectedMids, onChange: setSelectedMids, onClose: trackMidSelectorChange, children: _jsx(ButtonQuaternary, { icon: ActionIconChevronBottom, iconAlignment: "right", size: "small" }) }))] })] }), _jsx(SpacerVertical, { spaceToken: "space/200" }), _jsx(SalesThisWeekWidget, { isWide: isWide, isWidgetLoading: loading, queryParams: queryParams, currency: currency }), _jsx(SpacerVertical, { spaceToken: isWide ? 'space/400' : 'space/200' }), _jsx(LastUpdatedWidget, { mids: selectedMids.length ? selectedMids : mids })] }), _jsx(OrdersLink, { label: t('home-fe.insightsWidget.link') })] }));
};
function SingleStoreLabel({ storeName, mid }) {
    return (_jsxs(Flex, { spacing: "space/50", alignItems: "center", children: [_jsx(Typography, { textToken: "text-style/text/labels/default/regular", children: storeName }), _jsx(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral", children: "\u00B7" }), _jsx(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral", children: mid })] }));
}
SingleStoreLabel.propTypes = {
    storeName: PropTypes.string.isRequired,
    mid: PropTypes.string.isRequired
};
export default SalesThisWeek;

import insights from 'mage-insights'
import React, { useEffect, useState } from 'react'

import { useApiKey } from '../../hooks/useApiKey'
import { useClientId } from '../../hooks/useClientId'
import { CollapsibleStepsContext, CollapsibleStepsContextType } from './context'

type CollapsibleStepsProviderProps = {
  children: React.ReactNode;
}

const CLICK_EVENT = {
  action: 'click'
}

const CollapsibleStepsProvider: React.FC<CollapsibleStepsProviderProps> = ({
  children
}) => {
  const [isFirstStepOpen, setIsFirstStepOpen] = useState<boolean>(true)
  const [isSecondStepOpen, setIsSecondStepOpen] = useState<boolean>(true)
  const [isThirdStepOpen, setIsThirdStepOpen] = useState<boolean>(true)

  const { clientIdToken } = useClientId()
  const { hasAlreadyActiveKeys } = useApiKey()

  useEffect(() => {
    if (!!clientIdToken && hasAlreadyActiveKeys) {
      setIsThirdStepOpen(true)
    }
  }, [clientIdToken, hasAlreadyActiveKeys])

  function toggleFirstStep() {
    insights.event({
      ...CLICK_EVENT,
      category: 'v3/boost-home/install-plugin/collapse'
    })

    setIsFirstStepOpen(value => !value)
  }

  function toggleSecondStep() {
    insights.event({
      ...CLICK_EVENT,
      category: 'v3/boost-home/enable-plugin/collapse'
    })

    setIsSecondStepOpen(value => !value)
  }

  function toggleThirdStep() {
    insights.event({
      ...CLICK_EVENT,
      category: 'v3/boost-home/tailor-feature/collapse'
    })

    setIsThirdStepOpen(value => !value)
  }

  const value: CollapsibleStepsContextType = {
    isFirstStepOpen,
    isSecondStepOpen,
    isThirdStepOpen,
    toggleFirstStep,
    toggleSecondStep,
    toggleThirdStep
  }

  return (
    <CollapsibleStepsContext.Provider value={value}>
      {children}
    </CollapsibleStepsContext.Provider>
  )
}

export default CollapsibleStepsProvider

import { ButtonQuaternary, Loader, SpacerVertical } from '@klarna/bubble-ui'
import { Layout, Modal } from '@klarna/mp-ui'
import { TokenHelper } from '@mpp/token-helper'
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core'
import { hooks as i18nHooks } from 'mage-i18n'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useInvites, usePartners, useStores } from './hooks'
import Invite, { InviteChoices } from './Invite'
import { Choice, ChoiceValue, Entity, Partners, Stores } from './types'

type AcceptInviteModalProps = {
  reload?: () => void;
}

const emptyArray = []

const reloadDefault = () => window.location.assign('/')

const updateChoice = (stores: Stores, partners: Partners, value?: ChoiceValue) => (entity: Entity) => {
  return (entity.type === 'merchant') ?
    {
      entityId: entity.id,
      entityType: entity.type,
      name: stores[entity.id] || entity.id,
      value
    } : {
      entityId: entity.id,
      entityType: entity.type,
      name: partners[entity.id] || entity.id,
      value
    }
}

const AcceptInviteModal = (props: AcceptInviteModalProps) => {
  const { reload = reloadDefault } = props
  const t = i18nHooks.useTranslator()
  const tokenHelper = coreHooks.useTokenHelper() as TokenHelper
  const userId = tokenHelper.getUserId()

  const pendingEntities = useSelector<unknown, Entity[]>(coreSelectors.getPendingEntities)
  const emberClient = useSelector(coreSelectors.createBackendClient)('users')
  const mpApiClient = useSelector(coreSelectors.createBackendClient)('merchant-portal')

  const [choices, setChoices] = useState<Choice[]>(emptyArray)
  const { isLoading: isStoresLoading, stores } = useStores(mpApiClient, pendingEntities)
  const { isLoading: isPartnersLoading, partners } = usePartners(mpApiClient, pendingEntities)
  const { isInviting, errors: inviteErrors, handleInvites } = useInvites(emberClient, userId, choices, reload)

  useEffect(() => {
    const updatedChoices = pendingEntities.map(updateChoice(stores, partners))
    setChoices(updatedChoices)
  }, [pendingEntities, stores, partners])

  const hasOpenInvites = pendingEntities.length > 0
  const choicesMade = choices.filter(choice => choice.value).length > 0

  const handleInviteAcceptAll = () => {
    setChoices(pendingEntities.map(updateChoice(stores, partners, InviteChoices.ACCEPT)))
  }
  const handleInviteDeclineAll = () => {
    setChoices(pendingEntities.map(updateChoice(stores, partners, InviteChoices.DECLINE)))
  }
  const handleInviteChoice = (choice: Choice, value: ChoiceValue) => {
    setChoices(prevChoices => {
      return prevChoices.map(prevChoice => {
        const newValue = (prevChoice.entityType === choice.entityType && prevChoice.entityId === choice.entityId) ? value : prevChoice.value
        return {
          ...prevChoice,
          value: newValue
        }
      })
    })
  }

  return hasOpenInvites ? (
    <Modal
      title={t('home-fe.accept-invite-modal.title')}
      description={t('home-fe.accept-invite-modal.description')}
      generalError={inviteErrors.length ? t('home-fe.accept-invite-modal.error') : undefined}
      onClose={reload}
      buttonPrimary={{
        disabled: !choicesMade,
        text: t('home-fe.accept-invite-modal.save-button'),
        onClick: handleInvites,
        loading: isInviting
      }}
    >
      {(isStoresLoading || isPartnersLoading) ? (
        <Layout.Grid>
          <Layout.Section alignItems='center'>
            <Layout.Column alignItems='center'>
              <Loader large />
            </Layout.Column>
          </Layout.Section>
        </Layout.Grid>
      ) : (
        <>
          <div>
            <Layout.Grid>
              <Layout.Section>
                <Layout.Column mobileWidth={6}>
                  <ButtonQuaternary onClick={handleInviteAcceptAll}>
                    {t('home-fe.accept-invite-modal.accept-all-button')}
                  </ButtonQuaternary>
                </Layout.Column>
                <Layout.Column mobileWidth={6}>
                  <ButtonQuaternary onClick={handleInviteDeclineAll}>
                    {t('home-fe.accept-invite-modal.decline-all-button')}
                  </ButtonQuaternary>
                  <SpacerVertical spaceToken='space/500' />
                </Layout.Column>
              </Layout.Section>
            </Layout.Grid>
          </div>
          {choices.map(choice => (
            <Invite
              hasError={!!inviteErrors.find(inviteError => inviteError.entityId === choice.entityId && inviteError.entityType === choice.entityType)}
              isDisabled={isInviting}
              key={`invite-${choice.entityId}`}
              choice={choice}
              onChange={handleInviteChoice}
            />
          ))}
        </>
      )}
    </Modal>
  ) : null
}

export default AcceptInviteModal

export { AcceptInviteModal }

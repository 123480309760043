import { useEffect, useState } from 'react'

import { InviteChoices } from './Invite'
import { Choice, Entity, EntityType, InviteError, Partners, Stores } from './types'

const emptyObject = {}

const mapToEntityId = (entityType: EntityType, entities: Entity[]) => {
  return entities.filter(({ type }) => type === entityType)
    .map(({ id }) => id)
}

const useStores = (client, pendingEntities: Entity[]) => {
  const [isLoading, setIsLoading] = useState(false)
  const [stores, setStores] = useState<Stores>(emptyObject)

  useEffect(() => {
    const fetchStores = async (client, mids) => {
      try {
        setIsLoading(true)
        const { data } = await client.request({
          method: 'GET',
          url: '/merchants',
          params: {
            mid: mids
          }
        })
        setIsLoading(false)
        const updatedStores = data.reduce((currentStores, store) => ({
          ...currentStores,
          [store.merchant_id]: store.store_name
        }), {})
        setStores(updatedStores)
      } catch (error) {
        setIsLoading(false)
        return {}
      }
    }
    const mids = mapToEntityId('merchant', pendingEntities)
    if (mids.length) {
      fetchStores(client, mids)
    }
  }, [client, pendingEntities])

  return { isLoading, stores }
}

const usePartners = (client, pendingEntities: Entity[]) => {
  const [isLoading, setIsLoading] = useState(false)
  const [partners, setPartners] = useState<Partners>(emptyObject)

  useEffect(() => {
    const fetchPartners = async (client, partnerAccountIds) => {
      try {
        setIsLoading(true)
        const requestPromises = partnerAccountIds.map(partnerAccountId => {
          return client.request({
            method: 'GET',
            url: `/partners/${partnerAccountId}`
          })
        })
        const responses = await Promise.all(requestPromises)
        setIsLoading(false)
        const updatedPartners = responses.reduce((currentStores, response) => ({
          ...currentStores,
          [response.data.id]: response.data.name
        }), {})
        setPartners(updatedPartners)
      } catch (error) {
        setIsLoading(false)
        return []
      }
    }
    const partnerAccountIds = mapToEntityId('klarna_resource', pendingEntities)
    if (partnerAccountIds.length) {
      fetchPartners(client, partnerAccountIds)
    }
  }, [client, pendingEntities])

  return { isLoading, partners }
}

const useInvites = (client, userId: string, choices: Choice[], handleInvite: () => void) => {
  const [isInviting, setIsInviting] = useState(false)
  const [errors, setErrors] = useState<InviteError[]>([])

  const handleInvites = async () => {
    setIsInviting(true)
    const invitePromises = choices.map<InviteError | object>(choice => {
      switch (choice.value) {
        case InviteChoices.ACCEPT:
          return client.put(`/users/${userId}/entities`, {
            entity_type: choice.entityType,
            entity_id: choice.entityId,
            status: 'ACTIVE'
          })
            .catch(ex => ({
              ...choice,
              error: ex
            }))
        case InviteChoices.DECLINE:
          return client.delete(`/users/${userId}/entities?entity_type=${choice.entityType}&entity_id=${choice.entityId}`)
            .catch(ex => ({
              ...choice,
              error: ex
            }))
      }
    })
    setIsInviting(false)
    const responses = await Promise.all(invitePromises)
    const inviteErrors = responses.filter<InviteError>(response => 'error' in response)
    if (inviteErrors.length) {
      setErrors(inviteErrors)
    } else {
      handleInvite()
    }
  }

  return { isInviting, errors, handleInvites }
}

export {
  useInvites,
  usePartners,
  useStores
}

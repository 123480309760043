
import { SpacerVertical, Typography } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled from 'styled-components'

import { useActiveBoostProducts } from '../../hooks/useActiveBoostProducts'
import { useCollapsibleSteps } from '../../hooks/useCollapsibleSteps'
import { CollapsibleStep } from '../CollapsibleStep'
import BoostApps from './BoostApps'
import NoCredentialsMessage from './NoCredentialsMessage'

const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.configure-boost-features'

const InnerContainer = styled.div`
  text-align: left;
`

export default function StepConfigureBoostFeatures() {
  const t = i18nHooks.useTranslator()

  const {
    areCredentialsReady
  } = useActiveBoostProducts()

  const { isThirdStepOpen, toggleThirdStep } = useCollapsibleSteps()

  return (
    <CollapsibleStep
      index={t(`${TRANSLATION_PREFIX}.stepNumber`)}
      title={t(`${TRANSLATION_PREFIX}.title`)}
      onClick={toggleThirdStep}
      isOpen={isThirdStepOpen}
      closedContent={NoCredentialsMessage}
    >
      <InnerContainer>
        <Typography textToken="text-style/text/paragraphs/body/regular">
          {t(`${TRANSLATION_PREFIX}.description`)}
        </Typography>
        {!areCredentialsReady &&
        <>
          <SpacerVertical spaceToken='space/200' />
          <Typography textToken="text-style/text/paragraphs/body/regular">
            {t(`${TRANSLATION_PREFIX}.no-credentials`)}
          </Typography>
        </>
      }

      </InnerContainer>
      <BoostApps />
    </CollapsibleStep>
  )
}

import { ActionIconChevronRight, DesignTokens } from '@klarna/bubble-ui'
import { useGetToken } from '@klarna/mp-ui'
import React from 'react'
import styled from 'styled-components'

import { SelectorDirectOption } from './types'

interface TaskItemsProps {
  options: SelectorDirectOption[];
  onSelect: (value: SelectorDirectOption['value']) => void;
}

const TaskItemsStyled = styled.ul(() => {
  const paddingHorizontal = useGetToken<DesignTokens.Size>('space/100').value()

  return {
    listStyle: 'none',
    padding: `0 ${paddingHorizontal}px`,
    margin: 0
  }
})

const TaskItemWrapperStyled = styled.li(() => {
  const backgroundColor = useGetToken<DesignTokens.Color>('colors/backgrounds/subtle-inline').toString()
  const marginLeft = useGetToken<DesignTokens.Size>('space/400').value()
  return {
    ':after': {
      content: '" "',
      height: 1,
      backgroundColor,
      display: 'block',
      marginLeft
    },
    ':last-of-type:after': {
      display: 'none'
    }
  }
})

// @ts-ignore: FIXME
const TaskItemStyled = styled.a(() => {
  const spacing = useGetToken<DesignTokens.Size>('space/200').value()
  const borderRadius = useGetToken<DesignTokens.Size>('corner-radius/s').value()
  const { lineHeight, ...fontStyles } = useGetToken<DesignTokens.TextStyle>('text-style/text/super-paragraphs/body/regular').props
  const textColor = useGetToken<DesignTokens.Color>('colors/text/default').toString()
  const backgroundHoverColor = useGetToken<DesignTokens.Color>('colors/backgrounds/subtle-inline').toString()

  return {
    ...fontStyles,
    borderRadius,
    cursor: 'pointer',
    lineHeight: `${lineHeight}px`,
    display: 'flex',
    padding: spacing,
    color: textColor,
    gap: spacing,
    transition: 'background-color 0.15s ease-out',

    ':hover': {
      backgroundColor: backgroundHoverColor
    }
  }
})

const TaskItemLabelStyled = styled.span(() => {
  return {
  }
})

const ArrowIconStyled = styled.span(() => {
  return {
    marginLeft: 'auto',
    marginRight: 0
  }
})

export function TaskItems ({ options, onSelect }: TaskItemsProps) {
  return (
    <TaskItemsStyled>
      {
        options.map(({ value, label, icon }) => {
          return (
            <TaskItemWrapperStyled key={label}>
              <TaskItemStyled onClick={() => onSelect(value)}>
                {icon}
                <TaskItemLabelStyled>{label}</TaskItemLabelStyled>
                <ArrowIconStyled>
                  <ActionIconChevronRight colorToken='colors/borders/neutral' />
                </ArrowIconStyled>
              </TaskItemStyled>
            </TaskItemWrapperStyled>
          )
        })
      }
    </TaskItemsStyled>
  )
}

import { SpacerVertical } from '@klarna/bubble-ui'
import { Layout } from '@klarna/mp-ui'
import { usePageContext } from '@merchant-portal/framework'
import { components as clientComponents } from 'mage-client'
import { AccessDenied } from 'mage-components'
import { hooks as coreHooks } from 'mage-core'
import { hooks as i18nHooks } from 'mage-i18n'
import React, { useEffect } from 'react'

import { CAPS } from '../../constants'
import AppCategories from './AppCategories'
import * as banners from './banners'
import NkoWelcomeEnhanced from './NkoWelcome'
import Widgets from './Widgets'

const Home = () => {
  const { setPageContext = () => {} } = usePageContext()
  const t = i18nHooks.useTranslator()
  const tokenHelper = coreHooks.useTokenHelper()
  const isTransactingUser = (tokenHelper !== undefined) ? tokenHelper.isTransactingUser() : false

  useEffect(() => {
    setPageContext({
      title: t('core.apps.home-fe.title')
    })
  }, [t])

  return (
    <clientComponents.CapabilitiesProvider
      AccessDeniedComponent={AccessDenied}
      desired={CAPS}
    >
      <div id='home-page'>
        <Layout.Grid>
          <Layout.Section>
            <Layout.Column>
              <banners.Important
                title={t('home-fe.important-banner.title')}
                content={t('home-fe.important-banner.content')}
                linkLabel={t('home-fe.important-banner.linkLabel')}
                linkHref='https://status.klarna.com/'
                shouldDisplay={false}
                regions={[]}
                envs={[]}
              />
              <NkoWelcomeEnhanced />
              <banners.Notification />
            </Layout.Column>
          </Layout.Section>
          {isTransactingUser && (
            <>
              <SpacerVertical spaceToken='space/200' />
              <Widgets />
            </>
          )}
          <SpacerVertical spaceToken='space/200' />
          <AppCategories />

          <SpacerVertical spaceToken='space/200' />
        </Layout.Grid>
      </div>
    </clientComponents.CapabilitiesProvider>
  )
}

export default Home

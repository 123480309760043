import { SpacerVertical, Typography } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'

import AppCard from '../../../../../components/AppCard'
import CardContainer from '../../../../../components/AppCard/CardContainer'
import { B2B_COLORS } from '../../../../../constants'
import useUserAccessibleApps from '../../../../../hooks/useUserAccessibleApps'
import supplementaryDataCoverImage from '../../../assets/app-card-spd.svg'
import brandingImage from '../../../assets/branding-cover.png'
import expressCheckoutCoverImage from '../../../assets/kec-cover.png'
import onSiteMessagingCoverImage from '../../../assets/on-site-messaging-cover.png'
import signInWithKlarnaCoverImage from '../../../assets/siwk-cover.png'

interface BoosterApp {
  backgroundColor: string;
  imageUrl: string;
  sortOrder: number;
}

function BoosterAppList () {
  const t = i18nHooks.useTranslator()

  const boosterApps: Record<string, BoosterApp> = {
    'kec-fe': {
      backgroundColor: B2B_COLORS.Peach,
      imageUrl: expressCheckoutCoverImage,
      sortOrder: 2
    },
    'siwk-fe': {
      backgroundColor: B2B_COLORS.Lavender,
      imageUrl: signInWithKlarnaCoverImage,
      sortOrder: 3
    },
    'upstream-fe': {
      backgroundColor: B2B_COLORS.Sky,
      imageUrl: onSiteMessagingCoverImage,
      sortOrder: 1
    },
    'branding-fe': {
      backgroundColor: B2B_COLORS.Vanilla,
      imageUrl: brandingImage,
      sortOrder: 4
    },
    'shopping-data-fe': {
      backgroundColor: B2B_COLORS.Mint,
      imageUrl: supplementaryDataCoverImage,
      sortOrder: 5
    }
  }
  const userAccessibleBoosterApps = useUserAccessibleApps(Object.keys(boosterApps))

  const apps = userAccessibleBoosterApps
    .map(app => ({
      title: t(`home-fe.boosters-home.booster-apps.${app.clientId}.title`),
      description: t(`home-fe.boosters-home.booster-apps.${app.clientId}.description`),
      callToAction: t(`home-fe.boosters-home.booster-apps.${app.clientId}.callToAction`),
      clientId: app.clientId,
      url: app.url,
      ...boosterApps[app.clientId]
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder)

  if (apps.length === 0) {
    return null
  }

  return (
    <>
      <SpacerVertical spaceToken='space/500' />
      <Typography textToken='text-style/headings/titles/bold/grande'>{t('home-fe.boosters-home.booster-apps.title')}</Typography>
      <SpacerVertical spaceToken='space/300' />
      <CardContainer>
        {apps.map(app => (
          <AppCard
            key={app.clientId}
            title={app.title}
            description={app.description}
            imageUrl={app.imageUrl}
            backgroundColor={app.backgroundColor}
            clientId={app.clientId}
            url={app.url}
            buttonLabel={app.callToAction}
          />)
        )}
      </CardContainer>
    </>
  )
}

export default BoosterAppList

import { selectors as coreSelectors } from 'mage-core';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toCamelCase } from '../utils';
const API_KEYS_BASE = '/external/api-keys';
export function useApiKeysClient({ mid, region }) {
    const createClient = useSelector(coreSelectors.createBackendClient);
    const client = useMemo(() => createClient('credentials'), [createClient]);
    return {
        getExistingApiKeys: useCallback(() => {
            const queryParams = new URLSearchParams({ mid });
            const url = `${API_KEYS_BASE}?${queryParams}`;
            return client.request({ method: 'GET', url, region })
                .then(res => toCamelCase(res.data));
        }, [client, mid, region]),
        createApiKey: useCallback(() => {
            return client.request({
                method: 'POST',
                url: API_KEYS_BASE,
                data: {
                    mid
                },
                region
            }).then(res => toCamelCase(res.data));
        }, [client, mid, region]),
        disableApiKey: useCallback((credentialId) => {
            return client.request({
                method: 'PATCH',
                url: `${API_KEYS_BASE}/${credentialId}`,
                data: {
                    mid,
                    updatePayload: {
                        enabled: false
                    }
                },
                region
            }).then(res => toCamelCase(res.data));
        }, [client, mid, region]),
        sendVerificationEmail: useCallback(() => {
            return client.request({
                method: 'POST',
                url: `${API_KEYS_BASE}/activation-request`,
                region
            }).then(res => toCamelCase(res.data));
        }, [client, mid, region]),
        enableApiKey: useCallback((credentialId) => {
            return client.request({
                method: 'PATCH',
                url: `${API_KEYS_BASE}/enable`,
                data: {
                    credentialId,
                    mid
                },
                region
            }).then(res => toCamelCase(res.data));
        }, [client, mid, region])
    };
}

import type { RadioGroupProps } from '@bubble-ui/molecule-radio-group/types/types'
import {
  RadioGroup,
  Separator,
  SpacerVertical,
  Typography
} from '@klarna/bubble-ui'
import { Layout } from '@klarna/mp-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'

import { Choice, ChoiceValue } from './types'

const InviteChoices = {
  ACCEPT: 'Accept',
  DECLINE: 'Decline'
} as const

type InviteProps = {
  choice: Choice;
  hasError?: boolean;
  isDisabled?: boolean;
  onChange: (choice: Choice, value: ChoiceValue) => void;
}

const Invite = (props: InviteProps) => {
  const {
    choice,
    hasError = false,
    isDisabled = false,
    onChange
  } = props
  const t = i18nHooks.useTranslator()

  const handleOnChange = (value: string) => {
    onChange(choice, value as ChoiceValue)
  }

  const radioGroupProps: RadioGroupProps = {
    isDisabled,
    isHorizontal: true,
    onChange: handleOnChange,
    options: [{
      value: InviteChoices.ACCEPT,
      label: t('home-fe.accept-invite-modal.invite.accept-label')
    }, {
      value: InviteChoices.DECLINE,
      label: t('home-fe.accept-invite-modal.invite.decline-label')
    }]
  }
  if (choice.value) {
    radioGroupProps.value = choice.value
  }

  return (
    <>
      <Separator />
      <SpacerVertical spaceToken='space/500' />
      <div>
        <Layout.Grid>
          <Layout.Section alignItems='center'>
            <Layout.Column mobileWidth={6}>
              <Typography
                colorToken={hasError ? 'colors/text/error' : 'colors/text/default'}
                textToken='text-style/text/paragraphs/body/bold'
              >
                {choice.name}
              </Typography>
              <Typography
                colorToken={hasError ? 'colors/text/error' : 'colors/text/neutral'}
                textToken='text-style/text/paragraphs/body/regular'
              >
                {choice.entityId}
              </Typography>
            </Layout.Column>
            <Layout.Column mobileWidth={6}>
              <RadioGroup {...radioGroupProps} />
            </Layout.Column>
          </Layout.Section>
        </Layout.Grid>
      </div>
      <SpacerVertical spaceToken='space/500' />
    </>
  )
}

export default Invite

export {
  InviteChoices,
  InviteProps
}

import styled from 'styled-components'

import { BREAKPOINT_PX } from '../../constants'

const CardContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 50px;
  row-gap: 30px;
  align-items: stretch;

  @media (min-width: ${BREAKPOINT_PX.TABLET}px) and (max-width: ${BREAKPOINT_PX.DESKTOP}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${BREAKPOINT_PX.TABLET}px) {
    grid-template-columns: auto;
  }
`

export default CardContainer

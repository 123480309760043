
import './CollapsibleStep.css'

import { Chip, IconChevronBottom, IconChevronTop, Typography } from '@klarna/bubble-ui'
import React from 'react'
import styled from 'styled-components'

import { StepContainer } from '../styled'

interface CollapsibleStepProps {
  isOpen: boolean;
  onClick: () => void;
  index: number;
  title: string;
  children: React.ReactNode;
  closedContent?: () => React.JSX.Element;
}

const ChipStyled = styled(Chip)`
  span {
    margin: 0 !important;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`

const CorrectTitleAlignement = styled.div<{ mustCorrect: boolean }>`
  position: relative;
  top: ${props => props.mustCorrect ? '8px' : '0'};
`

const InvisibleButton = styled.button`
  border: none;
  background: none;
  width: 24px;
  cursor: pointer;
`

export default function CollapsibleStep ({
  isOpen,
  onClick,
  index,
  title,
  closedContent,
  children
}: CollapsibleStepProps) {

  return (
    <StepContainer>
      <ChipStyled title={String(index)} />
      <SubContainer>
        <HeaderContainer>
          <CorrectTitleAlignement mustCorrect={!isOpen}>
            <Typography textToken="text-style/headings/titles/bold/grande">
              {title}
            </Typography>
          </CorrectTitleAlignement>
          <InvisibleButton onClick={onClick} data-testid='collapsible-step-button'>
            {isOpen ? <IconChevronTop/> : <IconChevronBottom/>}
          </InvisibleButton>
        </HeaderContainer>
        <div className={`opacity-transition${isOpen ? ' show' : ''}`}>
          {children}
        </div>
        {!isOpen && closedContent?.()}
      </SubContainer>
    </StepContainer>
  )
}

import { SpacerVertical } from '@klarna/bubble-ui'
import { Layout } from '@klarna/mp-ui'
import { TrackImpression } from 'mage-insights'
import React from 'react'

import AccessBanner from '../components/AccessBanner'
import LoaderOverlay from '../components/LoaderOverlay'
import MidSelector from '../components/MidSelector'
import { useShowMidSelector } from '../hooks/useShowMidSelector'
import ErrorBanner from './ErrorBanner'
import GuideTabs from './GuideTabs'
import { PromotionBanner } from './PromotionBanner'
import SetupBoostInfo from './SetupBoostInfo'

const analyticsEvent = {
  category: 'v3/boost-home/boost-dashboard-page',
  action: 'impression'
}

export default function Page () {
  const { mustShowMidSelector } = useShowMidSelector()

  return (
    <TrackImpression event={analyticsEvent}>
      <div id='boost-installation-guide'>
        <Layout.Grid>
          {mustShowMidSelector && (
            <Layout.Section>
              <Layout.Column mobileWidth={12} tabletWidth={12} desktopWidth={12}>
                <MidSelector maxWidth='320px' />
              </Layout.Column>
            </Layout.Section>
          )}

          <Layout.Section>
            <Layout.Column mobileWidth={12} tabletWidth={12} desktopWidth={12}>
              <PromotionBanner />
            </Layout.Column>
          </Layout.Section>

          <Layout.Section>
            <Layout.Column mobileWidth={12} tabletWidth={12} desktopWidth={12}>
              <ErrorBanner />
              <AccessBanner />
            </Layout.Column>
          </Layout.Section>

          <SpacerVertical spaceToken='space/600' />

          <Layout.Section>
            <Layout.Column mobileWidth={12} tabletWidth={12} desktopWidth={12}>
              <SetupBoostInfo />
            </Layout.Column>
          </Layout.Section>

          <SpacerVertical spaceToken='space/200' />

          <GuideTabs />
        </Layout.Grid>

        <LoaderOverlay />
      </div>
    </TrackImpression>
  )
}

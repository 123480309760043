
import { SpacerVertical } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled from 'styled-components'

import { useActiveBoostProducts } from '../../hooks/useActiveBoostProducts'
import { usePlugin } from '../../hooks/usePlugin'
import BoostApp from './BoostApp'

const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.configure-boost-features'
const URL_OSM_CONFIG = '/on-site-messaging/dashboard/placements'
const URL_SIWIK_CONFIG = '/siwk/config'

export const LinkContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`
export const AppContainer = styled.div`
  display: grid;
`

export default function BoostApps() {
  const t = i18nHooks.useTranslator()

  const {
    mustDisplayOSM,
    mustDisplayKEC,
    mustDisplaySIWK
  } = useActiveBoostProducts()

  const { pluginKECDocsLink } = usePlugin()

  return (
    <>
      {mustDisplayOSM && (
      <>
        <SpacerVertical spaceToken="space/200" />
        <BoostApp
            appId='osm'
            linkLabel={t(`${TRANSLATION_PREFIX}.apps.osm.link-label`)}
            description={t(`${TRANSLATION_PREFIX}.apps.osm.description`)}
            redirectionUrl={URL_OSM_CONFIG}
          />
        <SpacerVertical spaceToken="space/200" />
      </>
      )}
      {mustDisplayKEC && (
      <>
        <BoostApp
            appId='kec'
            linkLabel={t(`${TRANSLATION_PREFIX}.apps.kec.link-label`)}
            description={t(`${TRANSLATION_PREFIX}.apps.kec.description`)}
            redirectionUrl={pluginKECDocsLink}
            openInNewWindow={pluginKECDocsLink?.startsWith('http')}
          />
        <SpacerVertical spaceToken="space/200" />
      </>
      )}
      {mustDisplaySIWK && (
      <BoostApp
          appId='siwk'
          linkLabel={t(`${TRANSLATION_PREFIX}.apps.siwk.link-label`)}
          description={t(`${TRANSLATION_PREFIX}.apps.siwk.description`)}
          redirectionUrl={URL_SIWIK_CONFIG}
        />
      )}
    </>
  )
}

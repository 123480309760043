import { Typography } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled from 'styled-components'

import TrackableLink from '../../../../../../components/TrackableLink'

const UnderlinedWrapper = styled.div`
  text-decoration: underline;
`
const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.api-key'
const MANAGE_API_KEYS_PATH_NEW = '/settings/api-keys'
const ANALYTICS_CATEGORY = 'v3/boost-home/authenticate-and-connect/manage-api-keys'

export default function ManageApiKeyLink () {
  const t = i18nHooks.useTranslator()

  return (
    <TrackableLink href={MANAGE_API_KEYS_PATH_NEW} category={ANALYTICS_CATEGORY}>
      <UnderlinedWrapper>
        <Typography textToken="text-style/text/paragraphs/body/regular">
          {t(`${TRANSLATION_PREFIX}.manageApiKey`)}
        </Typography>
      </UnderlinedWrapper>
    </TrackableLink>
  )
}
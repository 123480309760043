import { useResponsiveContext } from '@klarna/mp-ui'
import insights from 'mage-insights'
import { useCallback, useState } from 'react'

enum BannerLocalStorageState {
  SHOWN = 'shown',
  HIDDEN = 'hidden'
}

const LOCAL_STORAGE_KEY = 'boostHomePromotionBannerState'

function getBannerStateFromLocalStorage(): boolean {
  const bannerState = localStorage.getItem(LOCAL_STORAGE_KEY)

  // if the banner state is not set, we should show the banner
  return bannerState === BannerLocalStorageState.SHOWN || bannerState === null
}

function setBannerStateToLocalStorage(state: BannerLocalStorageState) {
  localStorage.setItem(LOCAL_STORAGE_KEY, state)
}

export default function usePromotionBanner() {
  const [isShown, setIsShown] = useState<boolean>(getBannerStateFromLocalStorage())
  const { isDesktop, isXLDesktop } = useResponsiveContext()

  const shouldShowDesktopView = isDesktop || isXLDesktop

  const handleClose = useCallback(() => {
    insights.event({
      category: 'v3/boost-home/promotion-banner/closed',
      action: 'click'
    })

    setIsShown(false)
    setBannerStateToLocalStorage(BannerLocalStorageState.HIDDEN)
  }, [])

  return {
    handleClose,
    isShown,
    shouldShowDesktopView
  }
}

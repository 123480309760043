import React, { createContext, useContext, useState } from 'react'

import { PLUGINS } from '../../../constants'
import { getPluginFromUrl } from './utils/getPluginFromUrl'

export type Plugin = {
    pluginName: string;
    pluginDocUrl: string;
    pluginId: string;
    pluginLink: string;
    setPluginId?: React.Dispatch<React.SetStateAction<string>>;
    pluginKECDocsLink: string;
}

type PluginProviderProps = {
  children: React.JSX.Element;
  mustExtractPluginIdFromUrl?: boolean;
}

export const PluginContext = createContext<Plugin | undefined>(undefined)

export const PluginProvider = ({
  children,
  mustExtractPluginIdFromUrl = true
}: PluginProviderProps): React.JSX.Element | null => {
  const [pluginId, setPluginId] = useState<string>(mustExtractPluginIdFromUrl ? getPluginFromUrl() : '')

  const access: Plugin = {
    pluginId,
    pluginName: '',
    pluginDocUrl: '',
    pluginLink: '',
    setPluginId: mustExtractPluginIdFromUrl ? undefined : setPluginId,
    pluginKECDocsLink: ''
  }

  if (pluginId.length > 0) {
    const plugin = getPlugin(pluginId)

    access.pluginName = plugin?.name || ''
    access.pluginDocUrl = plugin?.docUrl || ''
    access.pluginLink = plugin?.pluginLink || ''
    access.pluginKECDocsLink = plugin?.kecDocsLink || ''
  }

  return (
    <PluginContext.Provider value={access}>{children}</PluginContext.Provider>
  )
}

const getPlugin = (pluginId: string) => PLUGINS.find(plugin => plugin.id === pluginId)

export const usePlugin = (): Plugin => {
  const value = useContext(PluginContext)

  if (!value) throw new Error('cannot get PluginContext as not been provided')

  return value
}

import { MenuTab, SpacerVertical } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import Insights from 'mage-insights'
import React, { useState } from 'react'

import PluginTabContent from './PluginTabContent'
import WebSdkTabContent from './WebSdkTabContent'


const ANALYTICS_CATE_PREFIX = 'home-fe/v3/boost-home/integration-type-tabs/'

type TabId = 'plugin' | 'websdk'

type Tab = {
  id: TabId;
  labelKey: string;
  component: React.ReactNode;
}

const TABS: Tab[] = [
  {
    id: 'plugin',
    labelKey: 'home-fe.boosters-home-v03.tabs.plugin',
    component: <PluginTabContent />
  },
  {
    id: 'websdk',
    labelKey: 'home-fe.boosters-home-v03.tabs.websdk',
    component: <WebSdkTabContent />
  }
]


const getOptions = (t) => (
  TABS.map(({ id, labelKey }) => ({
    value: id,
    label: t(labelKey)
  }))
)

export default function GuideTabs () {
  const [activeTab, setActiveTab] = useState<TabId>('plugin')
  const t = i18nHooks.useTranslator()

  const onTabChange = (activeTab: TabId) => {
    setActiveTab(activeTab)
    Insights.event({
      category: ANALYTICS_CATE_PREFIX + activeTab,
      action: 'click'
    })
  }

  return (
    <>
      <MenuTab
        value={String(activeTab)}
        onChange={activeTab => onTabChange(activeTab as TabId)}
        options={getOptions(t)}
      />
      <SpacerVertical spaceToken='space/400' />
      {TABS.find(({ id }) => id === activeTab)?.component}
    </>
  )
}

import { IconDone, IconInformation, IconNotification, IconWarning } from '@klarna/bubble-ui'
import { memoizeWithTokenHelper } from '@mpp/token-helper'
import { createSelector } from '@reduxjs/toolkit'
import { AppIcon } from 'mage-components'
import { selectors as coreSelectors } from 'mage-core'
import { selectors as i18nSelectors } from 'mage-i18n'
import { selectors as stateSelectors } from 'mage-state'
import * as R from 'ramda'
import React from 'react'

import { NAME } from './constants'

const homeState = R.propOr({}, NAME)

const getBanners = R.pipe(
  homeState,
  R.propOr([], 'banners')
)

const kustomConfigs = {
  pre: {
    state: 'pre-migration',
    icon: IconWarning,
    status: 'warning'
  },
  during: {
    state: 'during-migration',
    icon: IconInformation
  },
  completed: {
    state: 'completed-migration',
    icon: IconDone,
    status: 'success'
  }
}

const kustomMigrationLinks = {
  startDate: 'KUSTOM_STRIPE_MIGRATION_START_DATE',
  completedDate: 'KUSTOM_STRIPE_MIGRATION_COMPLETED_DATE'
}

const getKustomNotifications = createSelector(
  coreSelectors.getMerchants,
  i18nSelectors.translator,
  (merchants, t) => {
    return merchants.filter(({ links }) => links.find(R.propEq(kustomMigrationLinks.startDate, 'linkType')))
      .map(({ links }) => {
        const currentDate = new Date()
        const startDate = links.find(R.propEq(kustomMigrationLinks.startDate, 'linkType')).linkId
        const completedDate = links.find(R.propEq(kustomMigrationLinks.completedDate, 'linkType'))?.linkId
        let kustomConfig
        if (completedDate && currentDate >= new Date(completedDate)) {
          kustomConfig = kustomConfigs['completed']
        } else if (currentDate >= new Date(startDate)) {
          kustomConfig = kustomConfigs['during']
        } else {
          kustomConfig = kustomConfigs['pre']
        }
        return {
          title: t(`home-fe.banner.kustom.${kustomConfig.state}.title`),
          content: t(`home-fe.banner.kustom.${kustomConfig.state}.content`),
          link: 'https://portal.kustom.co',
          linkLabel: t(`home-fe.banner.kustom.${kustomConfig.state}.linkLabel`),
          Icon: kustomConfig.icon,
          status: kustomConfig.status
        }
      })
  }
)

const generateGetNewAppNotifications = createSelector(
  stateSelectors.getUserAccessibleApps,
  i18nSelectors.translator,
  (apps, t) => memoizeWithTokenHelper(
    tokenHelper => {
      return apps(tokenHelper)
        .filter(R.prop('newApp'))
        .map(({ clientId, url }) => ({
          content: t(`home-fe.banner.common.${clientId}.content`),
          link: url,
          linkLabel: t(`home-fe.banner.common.${clientId}.linkLabel`),
          Icon: () => <AppIcon color={'#000000'} clientId={clientId}/>
        }))
        .filter(
          R.both(R.prop('content'), R.prop('linkLabel'))
        )
    }
  )
)

const generateGetGeneralNotifications = createSelector(
  stateSelectors.getUserAccessibleApps,
  i18nSelectors.translator,
  getBanners,
  (apps, t, banners) => memoizeWithTokenHelper(
    tokenHelper => {
      return banners
        .filter(banner => {
          return apps(tokenHelper)
            .some(R.propEq(banner.app, 'clientId'))
        })
        .map(({ id, link, Icon = IconNotification }) => ({
          content: t(`home-fe.banner.${id}.content`),
          link,
          linkLabel: t(`home-fe.banner.${id}.linkLabel`),
          Icon
        }))
        .filter(notification => {
          return notification.content && notification.linkLabel
        })
    }
  )
)

const generateGetNotifications = createSelector(
  generateGetNewAppNotifications,
  generateGetGeneralNotifications,
  getKustomNotifications,
  (newAppNotifications, generalNotifications, kustomMigrationNotifications) => memoizeWithTokenHelper(
    tokenHelper => [
      ...generalNotifications(tokenHelper),
      ...newAppNotifications(tokenHelper),
      ...kustomMigrationNotifications
    ]
  )
)

const getShouldShowDialog = createSelector(
  coreSelectors.getRequester,
  requester => {
    return requester ? !requester.has_accepted_nko_terms : false
  }
)

export {
  generateGetGeneralNotifications,
  generateGetNewAppNotifications,
  generateGetNotifications,
  getKustomNotifications,
  getShouldShowDialog
}

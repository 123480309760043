import { AlertBannerWarning } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'

export default function ApiKeyBanner () {
  const t = i18nHooks.useTranslator()

  return (
    <AlertBannerWarning content={t('home-fe.boosters-home-v03.api-key.warning-banner.content')} />
  )
}

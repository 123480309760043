import { hooks as i18nHooks } from 'mage-i18n'
import React, { createContext, useContext } from 'react'

export type ErrorContext = {
    errorId?: string;
    sentryErrorId?: string;
    errorMessage?: string;
    setErrorToTranslate: (
      translationKey: string,
      errorId: string,
      sentryErrorId?: string
    ) => void;
    resetError: () => void;
}

type ErrorProviderProps = {
  children: React.JSX.Element;
}

export const errorContext = createContext<ErrorContext | undefined>(undefined)

export const ErrorProvider = ({
  children
}: ErrorProviderProps): React.JSX.Element | null => {
  const t = i18nHooks.useTranslator()

  const [errorId, setErrorId] = React.useState<string | undefined>(undefined)
  const [sentryErrorId, setSentryErrorId] = React.useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined)

  const setErrorToTranslate = (
    translationKey: string,
    errorId: string,
    sentryErrorId?: string
  ) => {
    setSentryErrorId(sentryErrorId)
    setErrorId(errorId)
    setErrorMessage(t(translationKey))
  }

  const resetError = () => {
    setErrorMessage(undefined)
    setSentryErrorId(undefined)
    setErrorId(undefined)
  }

  const accessController: ErrorContext = {
    errorId,
    sentryErrorId,
    errorMessage,
    setErrorToTranslate,
    resetError
  }

  return (
    <errorContext.Provider value={accessController}>
      {children}
    </errorContext.Provider>
  )
}

export const useError = (): ErrorContext => {
  const value = useContext(errorContext)

  if (!value) throw new Error('cannot get ErrorContext as not been provided')

  return value
}

import { hooks as coreHooks } from 'mage-core'
import { selectors as stateSelectors } from 'mage-state'
import { useSelector } from 'react-redux'

interface App {
  clientId: string;
  url: string;
  additionalRequirements: string[];
}

export default function useUserAccessibleApps (clientIds: string[]): App[] {
  const tokenHelper = coreHooks.useTokenHelper()
  const mpApps = useSelector(stateSelectors.getUserAccessibleApps) as (x: any) => any

  const apps = mpApps(tokenHelper)

  return apps.filter(app => clientIds.includes(app.clientId))
}

import { __awaiter } from "tslib";
import React, { createContext, useContext, useState } from 'react';
import useApiKeys from '../useApiKeys';
export const VerificationEmailModalContext = createContext(undefined);
export const VerificationEmailModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMid, setMid] = useState('');
    const { sendVerificationEmail } = useApiKeys({ mid: selectedMid !== null && selectedMid !== void 0 ? selectedMid : '', fetchingApiKeysInitially: false });
    const onSendEmailClick = () => __awaiter(void 0, void 0, void 0, function* () {
        yield sendVerificationEmail();
        setIsModalOpen(false);
    });
    const openVerificationModal = () => {
        setIsModalOpen(true);
    };
    const closeVerificationModal = () => {
        setIsModalOpen(false);
    };
    const setSelectedMid = (selectedMid) => {
        if (selectedMid !== null) {
            setMid(selectedMid);
        }
    };
    const value = {
        isModalOpen,
        onSendEmailClick,
        openVerificationModal,
        closeVerificationModal,
        setSelectedMid
    };
    return (React.createElement(VerificationEmailModalContext.Provider, { value: value }, children));
};
export const useVerificationEmailModal = (selectedMid) => {
    const context = useContext(VerificationEmailModalContext);
    if (!context) {
        throw new Error('useVerificationEmailModal must be used within a VerificationEmailModalProvider');
    }
    if (selectedMid !== null) {
        context === null || context === void 0 ? void 0 : context.setSelectedMid(selectedMid);
    }
    return context;
};

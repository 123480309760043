import { SpacerVertical } from '@klarna/bubble-ui'
import { Flex } from '@klarna/mp-ui'
import React, { ComponentProps } from 'react'

import CloseButton from './CloseButton'
import { DesktopView } from './DesktopView'
import MobileView from './MobileView'
import usePromotionBanner from './usePromotionBanner'

const containerFlexProps: ComponentProps<typeof Flex> = {
  alignItems: 'center',
  style: {
    borderRadius: '16px',
    backgroundColor: '#F6F8FA',
    padding: '40px',
    paddingBottom: '0',
    position: 'relative'
  },
  children: null
}

export default function PromotionBanner() {
  const { isShown, shouldShowDesktopView, handleClose } = usePromotionBanner()

  if (!isShown) {
    return null
  }

  return (
    <>
      <SpacerVertical spaceToken='space/600' />
      <Flex {...containerFlexProps}>
        <CloseButton onClick={handleClose} />

        {shouldShowDesktopView ? <DesktopView /> : <MobileView />}
      </Flex>
    </>
  )
}

import {
  ColoredIllustrationEmptyState,
  DesignTokens,
  IllustrationErrorExplodingBalloon,
  SpacerVertical,
  Typography
} from '@klarna/bubble-ui'
import { useGetToken } from '@klarna/mp-ui'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div(() => {
  const paddingHorizontal = useGetToken<DesignTokens.Size>('space/300').value()

  return {
    padding: `0 ${paddingHorizontal}px`
  }
})

type EmptyOrErrorComponentProps = {
  title: string;
  desc: string;
  isError?: boolean;
}

const EmptyOrErrorComponent = (props: EmptyOrErrorComponentProps) => {
  const { title, desc, isError } = props
  const dataTestId = (isError)
    ? 'illustration-error-exploding-ballon'
    : 'illustration-empty-state'
  const Illustration = (isError)
    ? IllustrationErrorExplodingBalloon
    : ColoredIllustrationEmptyState
  return (
    <Container>
      <Illustration data-testid={dataTestId} />
      <br />
      <Typography textToken='text-style/text/paragraphs/tall/regular'>{title}</Typography>
      <SpacerVertical spaceToken='space/100' />
      <Typography textToken='text-style/text/paragraphs/body/regular'>{desc}</Typography>
    </Container>
  )
}

export default EmptyOrErrorComponent

import { jsx as _jsx } from "react/jsx-runtime";
import { ActivityIndicator, Flex } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getLatestOrderDatesFromMetadata, insightsDateUtils } from '../model';
export const LastUpdatedWidget = ({ mids }) => {
    const t = i18nHooks.useTranslator();
    const merchantData = useSelector(coreSelectors.getMerchants);
    const lastOrderDates = getLatestOrderDatesFromMetadata({ merchantData, selectedMids: mids });
    if (!lastOrderDates.length) {
        return null;
    }
    const elapsedTime = insightsDateUtils.getShortestElapsedTime(lastOrderDates);
    if (!lastOrderDates.length || elapsedTime === null) {
        return null;
    }
    return (_jsx(Flex, { children: _jsx(ActivityIndicator, { label: t('home-fe.insightsWidget.elapsedTime', {
                hours: elapsedTime.elapsedHours,
                minutes: Math.max(elapsedTime.elapsedMinutes, 1)
            }) }) }));
};
LastUpdatedWidget.propTypes = {
    mids: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
};

import { useApiKeys } from '@merchant-portal/credentials'
import { useEffect, useState } from 'react'

type Props = {
  mid: string | null;
}

type HasActiveApiKey = {
  hasAlreadyActiveKeys: boolean;
  setHasAlreadyActiveKeys: (value: boolean) => void;
}

export const useHasActiveApiKeys = ({ mid }: Props): HasActiveApiKey => {
  const [hasAlreadyActiveKeys, setHasAlreadyActiveKeys] = useState<boolean>(false)

  const { getApiKeys } = useApiKeys({ mid: mid ?? '', fetchingApiKeysInitially: false })

  useEffect(() => {
    if (!mid) return

    let isCancelled = false

    getApiKeys()
      .then(apiKeys => {
        if (isCancelled) return

        setHasAlreadyActiveKeys(apiKeys.some(
          apiKey => apiKey.keyType === 'API' && apiKey.enabled === true
        ))
      })
      .catch(() => {
        if (isCancelled) return
        setHasAlreadyActiveKeys(false)
      })

    return () => {
      isCancelled = true
    }
  }, [mid])

  if (!mid) {
    return {
      hasAlreadyActiveKeys: false,
      setHasAlreadyActiveKeys
    }
  }

  return {
    hasAlreadyActiveKeys,
    setHasAlreadyActiveKeys
  }
}

import { jsx as _jsx } from "react/jsx-runtime";
import { selectors as coreSelectors } from 'mage-core';
import { thunks as stateThunks } from 'mage-state';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as clientSelectors from '../selectors';
import CapabilitiesContext from './CapabilitiesContext';
const returnNull = R.always(null);
const hasAccess = (desired, capabilities) => {
    const des = R.pipe(R.map(R.prop('KEY')), R.values())(desired);
    const caps = R.keys(capabilities);
    return R.any(d => R.includes(d, caps), des);
};
const CapabilitiesProvider = ({ capabilities, children, clientId, desired, error, ErrorComponent, getCapabilities, isLoading, AccessDeniedComponent, LoadingComponent, options }) => {
    useEffect(() => {
        getCapabilities(Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, options.params), { clientId }) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (isLoading) {
        return _jsx(LoadingComponent, {});
    }
    else if (error) {
        return _jsx(ErrorComponent, {});
    }
    else if (!hasAccess(desired, capabilities)) {
        return _jsx(AccessDeniedComponent, {});
    }
    else {
        return (_jsx(CapabilitiesContext.Provider, { value: capabilities, children: React.Children.only(children) }));
    }
};
CapabilitiesProvider.propTypes = {
    AccessDeniedComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    capabilities: PropTypes.object,
    children: PropTypes.node.isRequired,
    clientId: PropTypes.string,
    desired: PropTypes.object,
    error: PropTypes.object,
    ErrorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    getCapabilities: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    LoadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    options: PropTypes.object
};
CapabilitiesProvider.defaultProps = {
    capabilities: {},
    desired: {},
    error: null,
    ErrorComponent: returnNull,
    isLoading: false,
    LoadingComponent: returnNull,
    options: {}
};
export default R.compose(connect(state => ({
    isLoading: clientSelectors.isCapabilitiesLoading(state),
    error: clientSelectors.getCapabilitiesError(state),
    capabilities: clientSelectors.getCapabilities(state),
    clientId: coreSelectors.clientId(state)
}), {
    getCapabilities: stateThunks.fetchCapabilities
}))(CapabilitiesProvider);
export { CapabilitiesContext, CapabilitiesProvider };

import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core'
import insights from 'mage-insights'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useError } from './useError'
import { usePlugin } from './usePlugin'

export type Mid = {
  userMids: string[];
  selectedMid: string | null;
  selectedStoreName: string | null;
  handleMidChange: (mid: string) => void;
}

type MidProviderProps = {
  children: React.JSX.Element;
}

interface Merchant {
  merchantId: string;
  storeName: string;
}

export const MidContext = createContext<Mid | undefined>(undefined)

export const MidProvider = ({
  children
}: MidProviderProps): React.JSX.Element | null => {
  const { resetError } = useError()
  // @ts-ignore - coreHooks is not typed
  const userMids = coreHooks.useTokenHelper().getMids()
  const merchants = useSelector(coreSelectors.getMerchants) as unknown as Merchant[]

  const [selectedMid, setSelectedMid] = useState<string | null>(userMids[0] || null)
  const { pluginId } = usePlugin()

  coreHooks.useCacheMidInSessionStorage(selectedMid, setSelectedMid)

  const handleMidChange = (mid: string) => {
    resetError()
    setSelectedMid(mid)
  }

  useEffect(() => {
    if (!selectedMid) {
      setSelectedMid(userMids[0] || null)
    }
  }, [userMids])

  useEffect(() => {
    insights.event({
      category: `boost-home/plugin-guide/${pluginId}`,
      action: 'mid-selected',
      label: selectedMid ?? undefined
    })
  }, [selectedMid])

  const selectedStoreName = merchants.find(
    m => m.merchantId === selectedMid
  )?.storeName || null

  const midController: Mid = {
    userMids,
    selectedMid,
    selectedStoreName,
    handleMidChange
  }

  return (
    <MidContext.Provider value={midController}>{children}</MidContext.Provider>
  )
}

export const useMid = (): Mid => {
  const value = useContext(MidContext)

  if (!value) throw new Error('cannot get MidContext as not been provided')

  return value
}

import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import createRequests from './requests'
import tasksSlice from './tasksSlice'
import type { Task } from './types'

const pendingEntitiesToTask = entities => ({
  appId: 'users-fe',
  appType: 'users-fe:entity-invited',
  count: entities.length,
  url: '/?task=processStoreInvites'
})

type DataProviderProps = {
  includeOrders?: boolean;
  children: (tasks: Task[]) => React.ReactElement;
  onLoading?: () => React.ReactElement;
  onError?: () => React.ReactElement;
}

export default function DataProvider (props: DataProviderProps) {
  const { includeOrders, children, onLoading = () => null, onError = () => null } = props
  const createBackendClient = useSelector(coreSelectors.createBackendClient)
  const ordersClient = createBackendClient('orders')
  const notifierClient = createBackendClient('notifier')
  const tokenHelper = coreHooks.useTokenHelper()

  const [state, dispatch] = React.useReducer(tasksSlice.reducer, tasksSlice.getInitialState())
  const { tasks, isFetching, isError } = state
  const extraTasks: Task[] = []
  const pendingEntities = useSelector(coreSelectors.getPendingEntities)
  if (pendingEntities.length) {
    extraTasks.push(pendingEntitiesToTask(pendingEntities))
  }

  useEffect(() => {
    const fetchData = async () => {
      if (tokenHelper) {
        dispatch(tasksSlice.actions.init())
        try {
          const requests = createRequests({
            notifier: notifierClient,
            orders: ordersClient
          }, tokenHelper.getUserId(), tokenHelper.getRegion(), { includeOrders })
          const result = await Promise.all(requests)
          const payload = result.flat().filter(value => value !== undefined)

          dispatch(tasksSlice.actions.success(payload))
        } catch (error) {
          dispatch(tasksSlice.actions.failure())
        }
      }
    }
    fetchData()
  }, [tokenHelper])

  if (isFetching) {
    return onLoading()
  } else if (isError) {
    return onError()
  } else {
    return children([...tasks, ...extraTasks])
  }
}

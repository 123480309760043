import { selectors as experimentationSelectors } from '@merchant-portal/experimentation'
import React from 'react'
import { useSelector } from 'react-redux'

import { useMid } from '../useMid'
import { ShowMidSelector, ShowMidSelectorContext } from './context'


const IS_MID_SELECTOR_DISABLED_XP_KEY = 'merchant-portal.boost.pig.mid-selector.disabled'

type ProviderProps = {
  children: React.JSX.Element;
}

export const ShowMidSelectorProvider = ({
    children
}: ProviderProps): React.JSX.Element | null => {

  const features = useSelector(experimentationSelectors.features)
  const feature = features?.[IS_MID_SELECTOR_DISABLED_XP_KEY]
  const canHideMidSelector = feature?.variate_id === 'hide'

  const { userMids, handleMidChange } = useMid()

  const hasOneMid = userMids?.length === 1

  const mustShowMidSelector =
    !canHideMidSelector || !hasOneMid

  if (!mustShowMidSelector) {
    handleMidChange(userMids[0])
  }

  const midController: ShowMidSelector = {
    mustShowMidSelector
  }

  return (
    <ShowMidSelectorContext.Provider value={midController}>
      {children}
    </ShowMidSelectorContext.Provider>
  )
}

import { DesignTokens, SpacerVertical, Typography } from '@klarna/bubble-ui'
import { useGetToken } from '@klarna/mp-ui'
import { hooks as coreHooks } from 'mage-core'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled, { css } from 'styled-components'

import LoadingComponent from '../Loader'
import EmptyOrErrorComponent from './EmptyOrErrorComponent'
import TaskDataProvider from './TaskDataProvider'
import TaskListEnhanced from './TaskList'

const RoundedContainer = styled.div(() => {
  const borderColor = useGetToken<DesignTokens.Color>('colors/borders/subtle-floating').toString()
  const borderRadius = useGetToken<DesignTokens.Size>('corner-radius/m').value()
  const paddingVertical = useGetToken<DesignTokens.Size>('space/300').value()

  return css({
    border: `1px solid ${borderColor}`,
    borderRadius,
    padding: `${paddingVertical}px 0`
  })
})

const TitleContainer = styled.div(() => {
  const paddingHorizontal = useGetToken<DesignTokens.Size>('space/300').value()

  return {
    padding: `0 ${paddingHorizontal}px`
  }
})

function TaskListComponent () {
  const t = i18nHooks.useTranslator()
  const tokenHelper = coreHooks.useTokenHelper()
  const isTransactingUser = tokenHelper ? tokenHelper.isTransactingUser() : false
  const hasOrdersAccess = tokenHelper ? tokenHelper.hasClientForEntityType('merchant', 'orders-fe') : false

  if (!isTransactingUser) {
    return null
  }

  return (
    <RoundedContainer>
      <TitleContainer>
        <Typography textToken='text-style/headings/blocks/bold/grande'>
          {t('home-fe.tasks.title')}
        </Typography>
      </TitleContainer>

      <SpacerVertical spaceToken='space/300' />

      <TaskDataProvider
        includeOrders={hasOrdersAccess}
        onError={() => (<EmptyOrErrorComponent isError title={t('home-fe.tasks.error.title')} desc={t('home-fe.tasks.error.desc')} />)}
        onLoading={() => <LoadingComponent />}
      >
        {
          tasks => <TaskListEnhanced tasks={tasks} />
        }
      </TaskDataProvider>

    </RoundedContainer>
  )
}

export default TaskListComponent

import { hooks as coreHooks } from 'mage-core'
import React, { createContext, useContext } from 'react'

import { useMid } from './useMid'

const ACCESS_APP = 'settings-fe'

const API_KEY_PERMISSION = 'manage-api-credentials'
const BOOST_API_KEY_PERMISSION = 'manage-boost-api-credentials'
const CLIENT_ID_PERMISSION = 'manage-client-identifiers'

const ACCESS_WARNING_KEY_PREFIX = 'home-fe.boosters-plugin.access-warning.'

export type useAccess = {
  canCreateApiKey: boolean;
  canCreateClientId: boolean;
  warningMessageKey: string;
  accessWarningType: string;
}

type AccessProviderProps = {
  children: React.JSX.Element;
}

export const AccessContext = createContext<useAccess | undefined>(undefined)

export const AccessProvider = ({
  children
}: AccessProviderProps): React.JSX.Element | null => {
  const tokenHelper = coreHooks.useTokenHelper()
  const { selectedMid } = useMid()

  const canCreateApiKey = getCanCreateApiKey(tokenHelper, selectedMid)
  const canCreateClientId = getCanCreateClientId(tokenHelper, selectedMid)

  const accessWarningType = getAccessWarningType(canCreateApiKey, canCreateClientId)
  const warningMessageKey = `${ACCESS_WARNING_KEY_PREFIX}${accessWarningType}`

  const accessController: useAccess = {
    canCreateApiKey,
    canCreateClientId,
    warningMessageKey,
    accessWarningType
  }

  return (
    <AccessContext.Provider value={accessController}>{children}</AccessContext.Provider>
  )
}

const getCanCreateApiKey = (tokenHelper, selectedMid): boolean => [
  API_KEY_PERMISSION,
  BOOST_API_KEY_PERMISSION
].some(
  (permission) => tokenHelper.hasPermissionForMid(selectedMid, ACCESS_APP, permission)
)

const getCanCreateClientId = (tokenHelper, selectedMid): boolean =>
  tokenHelper.hasPermissionForMid(selectedMid, ACCESS_APP, CLIENT_ID_PERMISSION)

const getAccessWarningType = (canCreateApiKey: boolean, canCreateClientId: boolean): string => {
  if (!canCreateApiKey && !canCreateClientId) return 'both'
  if (!canCreateApiKey) return 'api'
  if (!canCreateClientId) return 'client'
  return ''
}

export const useAccess = (): useAccess => {
  const value = useContext(AccessContext)

  if (!value) throw new Error('cannot get AccessContext as not been provided')

  return value
}

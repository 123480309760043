import {
  Loader
} from '@klarna/bubble-ui'
import React from 'react'
import styled from 'styled-components'

import { useApiKey } from '../hooks/useApiKey'
import { useClientId } from '../hooks/useClientId'

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export default function LoaderOverlay () {
  const { isLoading: isClientIdLoading } = useClientId()
  const { isLoading: isApiKeyLoading } = useApiKey()

  const isLoading = isClientIdLoading || isApiKeyLoading

  return (
    <>
      {isLoading &&
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      }
    </>
  )
}
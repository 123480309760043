import { IconInformation, SpacerVertical, Typography } from '@klarna/bubble-ui'
import { Tooltip } from '@klarna/mp-ui'
import {
  AddApiKeySuccessModal,
  useVerificationEmailModal,
  VerificationEmailModal
} from '@merchant-portal/credentials'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled from 'styled-components'

import { useApiKey } from '../../hooks/useApiKey'
import { useMid } from '../../hooks/useMid'
import ApiKeyBanner from './ApiKeyBanner'
import GenerateButton from './GenerateButton'
import ManageApiKeyLink from './ManageApiKeyLink'

const Container = styled.div`
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  background-color: rgb(255, 255, 255);
  padding: 24px;
  text-align: left;
  position: relative;
`

const TooltipContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`
const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.api-key'

export default function ApiKeySection () {
  const {
    isSuccessModalOpen,
    createApiKeyData,
    hasAlreadyActiveKeys,
    onSuccessModalClose
  } = useApiKey()
  const t = i18nHooks.useTranslator()
  const { selectedMid } = useMid()
  const {
    isModalOpen: isVerificationModalOpen,
    onSendEmailClick: onSendVerificationEmailClick
  } = useVerificationEmailModal(selectedMid)

  return (
    <Container>
      <TooltipContainer>
        <Tooltip
          text={t(`${TRANSLATION_PREFIX}.tooltip`)}
          placement='bottom-end'
        >
          <IconInformation />
        </Tooltip>
      </TooltipContainer>

      <Typography textToken='text-style/headings/titles/bold/grande'>
        {t(`${TRANSLATION_PREFIX}.title`)}
      </Typography>

      <SpacerVertical spaceToken='space/100' />

      <Typography textToken='text-style/text/paragraphs/body/regular'>
        {t(`${TRANSLATION_PREFIX}.description`)}
      </Typography>

      <SpacerVertical spaceToken='space/300' />

      {
        hasAlreadyActiveKeys &&
        <>
          <ApiKeyBanner />
          <SpacerVertical spaceToken='space/300' />
        </>
      }

      <GenerateButton />

      {
        hasAlreadyActiveKeys &&
        <>
          <SpacerVertical spaceToken='space/300' />
          <ManageApiKeyLink />
        </>
      }

      {
        isSuccessModalOpen &&
        <AddApiKeySuccessModal
          data={createApiKeyData}
          onClose={onSuccessModalClose}
        />
      }

      {
        isVerificationModalOpen &&
        <VerificationEmailModal
          onSendEmailClick={onSendVerificationEmailClick}
          merchantId={selectedMid}
        />
      }
    </Container>
  )
}

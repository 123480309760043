
import { StoreSelector } from 'mage-components'
import React from 'react'
import styled from 'styled-components'

import { useMid } from '../hooks/useMid'

const MainContainer = styled.div<{ maxWidth?: string }>`
  max-width: ${props => props.maxWidth || '250px'};
`

interface Props {
  maxWidth?: string;
}

export default function MidSelector ({ maxWidth }: Props) {
  const {
    userMids,
    selectedMid,
    handleMidChange
  } = useMid()

  return (
    <MainContainer maxWidth={maxWidth}>
      <StoreSelector
        borderless
        mids={userMids}
        value={selectedMid === null ? undefined : selectedMid}
        // @ts-ignore: fix type of onChange
        onChange={handleMidChange}
      />
    </MainContainer>
  )
}

import { createContext } from 'react'

export type CollapsibleStepsContextType = {
  isFirstStepOpen: boolean;
  isSecondStepOpen: boolean;
  isThirdStepOpen: boolean;
  toggleFirstStep: () => void;
  toggleSecondStep: () => void;
  toggleThirdStep: () => void;
}

export const CollapsibleStepsContext = createContext<CollapsibleStepsContextType | undefined>(undefined)
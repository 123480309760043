import { ButtonSecondary, DesignTokens,Typography } from '@klarna/bubble-ui'
import Insights from 'mage-insights'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import CardWrapper from './CardWrapper'

const TopAlignment = styled.div`
  height: 100%;
`

type ImageProps = {
  imageUrl: string;
  backgroundColor: string;
}

const Image = styled.div<ImageProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom center;
  border-radius: 8px;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 45.28%;
  }
`

const CallToActionWrapper = styled.div`
  margin-top: 24px;
`

const Heading = styled.div`
  margin-bottom: 8px;
`

type AppCardProps = ImageProps & {
  title: string;
  description: string;
  clientId: string;
  url: string;
  buttonLabel: string;
  componentRef?: React.RefObject<HTMLDivElement>;
}

function AppCard ({ title, imageUrl, description, backgroundColor, clientId, url, buttonLabel, componentRef }: AppCardProps) {
  const category = `marketing-home/app-card/${clientId}`
  const designContext = DesignTokens.useDesignContext()

  const TextWrapper = styled.div`
    background: ${designContext.get<DesignTokens.Color>('colors/backgrounds/default').toString()};
    padding-top: 16px;
    text-align: left;
  `

  useEffect(() => {
    Insights.event({
      category,
      action: 'impression'
    })
  }, [])

  const onClick = () => {
    Insights.event({
      category,
      action: 'click'
    })
    location.href = url
  }

  return (
    <div ref={componentRef}>
      <CardWrapper onClick={onClick}>
        <TopAlignment>
          <Image backgroundColor={backgroundColor} imageUrl={imageUrl} />
          <TextWrapper>
            <Heading>
              <Typography textToken='text-style/headings/titles/bold/grande'>{title}</Typography>
            </Heading>
            <Typography textToken='text-style/text/paragraphs/default/regular'>
              {description}
            </Typography>
            <CallToActionWrapper>
              <ButtonSecondary size='medium' onClick={onClick} onPress={onClick}>
                {buttonLabel}
              </ButtonSecondary>
            </CallToActionWrapper>
          </TextWrapper>
        </TopAlignment>
      </CardWrapper>
    </div>
  )
}

export default AppCard

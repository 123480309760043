import { ActionIconClose, ButtonRoundTertiary } from '@klarna/bubble-ui'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

type Props = {
  onClick: () => void;
}

export default function CloseButton ({ onClick }: Props) {
  return (
    <Container>
      <ButtonRoundTertiary onClick={onClick} data-testid="btn-close">
        <ActionIconClose />
      </ButtonRoundTertiary>
    </Container>
  )
}

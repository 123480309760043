import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApiKeysClient } from './client';
export default function useApiKeys({ mid, region: argsRegion, fetchingApiKeysInitially = true }) {
    const getMerchantRegion = useSelector(coreSelectors.getMerchantRegion);
    const midRegion = useMemo(() => getMerchantRegion(mid), [getMerchantRegion, mid]);
    const region = argsRegion !== null && argsRegion !== void 0 ? argsRegion : midRegion;
    const [isLoading, setIsLoading] = useState(!!fetchingApiKeysInitially);
    const [apiKeys, setApiKeys] = useState([]);
    const [error, setError] = useState(null);
    const setErrorWithSentry = useCallback((e, message) => {
        const errorId = Sentry.captureException(e);
        const error = { id: errorId, message: message !== null && message !== void 0 ? message : e.message };
        setError(error);
        return error;
    }, []);
    const t = i18nHooks.useTranslator();
    const T_PREFIX = 'credentials.error';
    const api = useApiKeysClient({ mid, region });
    const getApiKeys = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            setIsLoading(true);
            const response = yield api.getExistingApiKeys();
            const responseApiKeys = (_a = response.credentials) !== null && _a !== void 0 ? _a : apiKeys;
            setApiKeys(responseApiKeys);
            setError(null);
            return responseApiKeys;
        }
        catch (e) {
            setApiKeys([]);
            // @ts-ignore: TODO: type guard `e`
            const generatedError = setErrorWithSentry(e, t(`${T_PREFIX}.getApiKeys`));
            throw generatedError;
        }
        finally {
            setIsLoading(false);
        }
    }), [api.getExistingApiKeys, mid, region]);
    const createApiKey = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        let createResponse;
        try {
            setIsLoading(true);
            createResponse = yield api.createApiKey();
            const getResponse = yield api.getExistingApiKeys();
            const apiKeys = getResponse.credentials;
            setApiKeys(apiKeys);
            setError(null);
            return createResponse;
        }
        catch (e) {
            const message = createResponse ? t(`${T_PREFIX}.message.gettingKeys`) : t(`${T_PREFIX}.message.creatingKey`);
            setApiKeys([]);
            // @ts-ignore: TODO: type guard `e`
            const generatedError = setErrorWithSentry(e, t(`${T_PREFIX}.general`, { message }));
            throw generatedError;
        }
        finally {
            setIsLoading(false);
        }
    }), [api.createApiKey, api.getExistingApiKeys, mid, region]);
    const disableApiKey = useCallback((credentialId) => __awaiter(this, void 0, void 0, function* () {
        let updateResponse;
        try {
            setIsLoading(true);
            updateResponse = yield api.disableApiKey(credentialId);
            const getResponse = yield api.getExistingApiKeys();
            const apiKeys = getResponse.credentials;
            setApiKeys(apiKeys);
            setError(null);
        }
        catch (e) {
            const message = updateResponse ? t(`${T_PREFIX}.message.gettingKeys`) : t(`${T_PREFIX}.message.updatingKey`);
            // @ts-ignore: TODO: type guard `e`
            const generatedError = setErrorWithSentry(e, t(`${T_PREFIX}.general`, { message }));
            throw generatedError;
        }
        finally {
            setIsLoading(false);
        }
    }), [api.disableApiKey, api.getExistingApiKeys, mid, region]);
    const sendVerificationEmail = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            yield api.sendVerificationEmail();
        }
        catch (e) {
            // @ts-ignore: TODO: type guard `e`
            setErrorWithSentry(e, t(`${T_PREFIX}.activatingApiKey`));
        }
        finally {
            setIsLoading(false);
        }
    }), [api.sendVerificationEmail, mid, region]);
    const enableApiKey = useCallback((credentialId) => __awaiter(this, void 0, void 0, function* () {
        let updateResponse;
        try {
            setIsLoading(true);
            updateResponse = yield api.enableApiKey(credentialId);
            const getResponse = yield api.getExistingApiKeys();
            const apiKeys = getResponse.credentials;
            setApiKeys(apiKeys);
            setError(null);
        }
        catch (e) {
            const message = updateResponse ? t(`${T_PREFIX}.message.gettingKeys`) : t(`${T_PREFIX}.message.updatingKey`);
            // @ts-ignore: TODO: type guard `e`
            const generatedError = setErrorWithSentry(e, t(`${T_PREFIX}.general`, { message }));
            throw generatedError;
        }
        finally {
            setIsLoading(false);
        }
    }), [api.enableApiKey, api.getExistingApiKeys, mid, region]);
    useEffect(() => {
        if (fetchingApiKeysInitially) {
            getApiKeys()
                // eslint-disable-next-line no-console
                .catch(console.error);
        }
    }, [fetchingApiKeysInitially]);
    return {
        isLoading,
        apiKeys,
        getApiKeys,
        createApiKey,
        disableApiKey,
        sendVerificationEmail,
        enableApiKey,
        error
    };
}

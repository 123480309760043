import { AlertBannerError, Frame } from '@klarna/bubble-ui'
import { TrackImpression } from 'mage-insights'
import React from 'react'
import styled from 'styled-components'

import { useError } from '../hooks/useError'
import { useMid } from '../hooks/useMid'

const MainContainer = styled.div`
  margin: 40px 0 0 0;
`

export default function ErrorBanner () {
  const { errorMessage, errorId, sentryErrorId } = useError()
  const { selectedMid } = useMid()

  const event = {
    category: `home-fe/v3/boost-home/error-banner/${errorId}`,
    action: 'impression',
    label: selectedMid ?? undefined
  }

  const content = sentryErrorId ? `${errorMessage} - ID: ${sentryErrorId}` : errorMessage

  return (
    <>
      {errorMessage &&
        <MainContainer>
          <TrackImpression event={event}>
            <Frame paddingToken="space/0">
              <AlertBannerError content={content} />
            </Frame>
          </TrackImpression>
        </MainContainer>
      }
    </>
  )
}

import { IconInformation, SpacerVertical, Typography } from '@klarna/bubble-ui'
import { Tooltip } from '@klarna/mp-ui'
import { AddOriginModal } from '@merchant-portal/credentials'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled from 'styled-components'

import { useClientId } from '../../hooks/useClientId'
import { useMid } from '../../hooks/useMid'
import GenerateButton from './GenerateButton'
import ManageClientId from './ManageClientId'

const Container = styled.div`
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  background-color: rgb(255, 255, 255);
  padding: 24px;
  text-align: left;
  position: relative;
`

const TooltipContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`
const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.client-identifier-section'

export default function ClientIdSection () {
  const { selectedMid } = useMid()
  const {
    isAddOriginModalVisible,
    onAddOriginSuccess,
    setIsAddOriginModalVisible,
    isGenerateButtonVisible
  } = useClientId()
  const t = i18nHooks.useTranslator()

  return (
    <Container>
      <TooltipContainer>
        <Tooltip
          text={t(`${TRANSLATION_PREFIX}.tooltip`)}
          placement='bottom-end'
        >
          <IconInformation />
        </Tooltip>
      </TooltipContainer>

      <Typography textToken='text-style/headings/titles/bold/grande'>
        {t(`${TRANSLATION_PREFIX}.title`)}
      </Typography>

      <SpacerVertical spaceToken='space/100' />

      <Typography textToken='text-style/text/paragraphs/body/regular'>
        {t(`${TRANSLATION_PREFIX}.description`)}
      </Typography>

      <SpacerVertical spaceToken='space/300' />

      {isGenerateButtonVisible ? <GenerateButton /> : <ManageClientId />}

      {selectedMid &&
        <AddOriginModal
          mid={selectedMid}
          onSuccess={onAddOriginSuccess}
          isVisible={isAddOriginModalVisible}
          setIsVisible={setIsAddOriginModalVisible}
        />
      }
    </Container>
  )
}

import { Typography } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled from 'styled-components'

import { useCollapsibleSteps } from '../hooks/useCollapsibleSteps'
import { CollapsibleStep } from './CollapsibleStep'


const InnerContainer = styled.div`
  text-align: left;
`

export default function StepStartSetup() {
  const t = i18nHooks.useTranslator()
  const { isSecondStepOpen, toggleSecondStep } = useCollapsibleSteps()

  return (
    <CollapsibleStep
        index={t('home-fe.boosters-home-v03.start-setup.stepNumber')}
        title={t('home-fe.boosters-home-v03.start-setup.title')}
        onClick={toggleSecondStep}
        isOpen={isSecondStepOpen}
      >
      <InnerContainer>
        <Typography textToken="text-style/text/paragraphs/body/regular">
          {t('home-fe.boosters-home-v03.start-setup.description')}
        </Typography>
      </InnerContainer>
    </CollapsibleStep>
  )
}

import { TokenHelper } from '@mpp/token-helper'
import { hooks as coreHooks } from 'mage-core'
import { selectors as stateSelectors } from 'mage-state'
import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'

import { useApiKey } from './useApiKey'
import { useClientId } from './useClientId'
import { useMid } from './useMid'
import { getMustDisplayBoostApp } from './utils/getMustdDisplayBoostApp'

const IS_SIWK_ENABLED_FOR_PLUGINS = false

const KEC_APP_ID = 'kec-fe'
const OSM_APP_ID = 'upstream-fe'
const SIWK_APP_ID = 'siwk-fe'

export type ActiveBoostProducts = {
  mustDisplayOSM: boolean;
  mustDisplayKEC: boolean;
  mustDisplaySIWK: boolean;
  areCredentialsReady: boolean;
}

type ActiveBoostProductsProviderProps = {
  children: React.JSX.Element;
}

export const ActiveBoostProductsContext = createContext<ActiveBoostProducts | undefined>(undefined)

export const ActiveBoostProductsProvider = ({ children }: ActiveBoostProductsProviderProps): React.JSX.Element | null => {
  const tokenHelper = coreHooks.useTokenHelper()
  const { selectedMid } = useMid()
  const { clientIdToken } = useClientId()
  const { hasAlreadyActiveKeys } = useApiKey()

  const getApps = useSelector(stateSelectors.getUserAccessibleApps) as (x: any) => any

  const availableApps = getApps(tokenHelper) || []
  const permissions = getMidPermissions(selectedMid, tokenHelper)

  const input = {
    selectedMid,
    clientId: clientIdToken,
    hasKeys: hasAlreadyActiveKeys,
    availableApps,
    permissions
  }

  const mustDisplayOSM = getMustDisplayBoostApp({ ...input, appId: OSM_APP_ID })
  const mustDisplayKEC = getMustDisplayBoostApp({ ...input, appId: KEC_APP_ID })
  const mustDisplaySIWK = getMustDisplayBoostApp({ ...input, appId: SIWK_APP_ID }) && IS_SIWK_ENABLED_FOR_PLUGINS

  const activeBoostProducts: ActiveBoostProducts = {
    mustDisplayOSM,
    mustDisplayKEC,
    mustDisplaySIWK,
    areCredentialsReady: hasAlreadyActiveKeys && !!clientIdToken
  }

  return (
    <ActiveBoostProductsContext.Provider value={activeBoostProducts}>
      {children}
    </ActiveBoostProductsContext.Provider>
  )
}

const getMidPermissions = (selectedMid: string | null, tokenHelper?: TokenHelper) => {
  if (!tokenHelper || !selectedMid) {
    return {}
  }
  return tokenHelper.getAllPermissionsForMid(selectedMid)
}

export const useActiveBoostProducts = (): ActiveBoostProducts => {
  const value = useContext(ActiveBoostProductsContext)

  if (!value) throw new Error('cannot get ActiveBoostProductsContext as not been provided')

  return value
}

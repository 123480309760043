
import React from 'react'

import { getImagePath } from './utils/getImagePath'

type PluginLogoProps = {
  imageName: string;
  height?: string;
}

export default function PluginLogo ({ imageName, height = '36px' }: PluginLogoProps) {
  const imagePath = getImagePath(imageName)

  return <img src={imagePath} style={{ height }} />
}

import { hooks as coreHooks } from 'mage-core'
import { selectors as stateSelectors } from 'mage-state'
import { useSelector } from 'react-redux'

interface App {
  clientId: string;
  url: string;
}

export default function useExternalAccessibleApps (): App[] {
  const getUserAccessibleApps = useSelector(stateSelectors.getUserAccessibleApps) as (x: any) => any
  const tokenHelper = coreHooks.useTokenHelper()
  const userAccessibleApps = getUserAccessibleApps(tokenHelper)

  return userAccessibleApps.filter(app => app?.isExternalApp)
}

interface GetMustDisplayBoostAppDTO {
  selectedMid: string | null;
  clientId: string;
  hasKeys: boolean;
  availableApps: any[];
  permissions: any;
  appId: string;
}

export const getMustDisplayBoostApp = ({
  selectedMid,
  clientId,
  hasKeys,
  availableApps,
  permissions,
  appId
}: GetMustDisplayBoostAppDTO): boolean => {

   const areCredentialsReady = !!selectedMid && !!clientId && hasKeys

   return areCredentialsReady
     && isAppEnabled(availableApps, appId)
     && hasAnyAccess(permissions, appId)
}

const isAppEnabled = (apps: any[], appId: string): boolean =>
  apps.find(app => app.clientId === appId) !== undefined

const hasAnyAccess = (permissions: any, appId: string): boolean => {
  if (!permissions || !permissions[appId]) return false
  return permissions[appId].length > 0
}
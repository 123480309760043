
import { ActionIconArrowRight, SpacerVertical, Typography } from '@klarna/bubble-ui'
import React from 'react'
import styled from 'styled-components'

import TrackableLink from '../../../../../../components/TrackableLink'


export const AppContainer = styled.div`
  display: grid;
`

const IconContainer = styled.div`
  padding: 2px 0 0 5px;
`
export const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
`
export const NoWrapWrapper = styled.div`
  white-space: nowrap;
`


type BoostAppProps = {
  appId: string;
  linkLabel: string;
  description: string;
  redirectionUrl: string;
  openInNewWindow?: boolean;
}

export default function BoostApp({ linkLabel, description, appId, redirectionUrl, openInNewWindow = false }: BoostAppProps) {
  return (
    <AppContainer>
      <LinkContainer>
        <NoWrapWrapper>
          <TrackableLink
            href={redirectionUrl}
            category={`v3/boost-home/config-boost/${appId}`}
            colorToken='colors/text/default'
            openInNewWindow={openInNewWindow}
          >
            {linkLabel}
          </TrackableLink>
        </NoWrapWrapper>
        <IconContainer>
          <ActionIconArrowRight />
        </IconContainer>
        <SpacerVertical spaceToken='space/200' />
      </LinkContainer>
      <Typography textToken='text-style/text/paragraphs/default/regular'>
        {description}
      </Typography>
    </AppContainer>
  )
}

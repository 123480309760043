import React from 'react'
import styled from 'styled-components'

import isTouchDevice from './isTouchDevice'

const Wrapper = styled.div`
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: ${({ onClick }) => (isTouchDevice() && onClick ? 'pointer' : 'auto')};
`

interface CardWrapperProps {
  onClick?: () => void;
  children: React.ReactNode;
}

export default function CardWrapper ({ children, onClick }: CardWrapperProps) {
  return (
    <Wrapper onClick={!isTouchDevice() ? onClick : undefined} as={!isTouchDevice() ? 'button' : undefined}>
      {children}
    </Wrapper>
  )
}

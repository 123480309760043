import { Separator, SpacerVertical } from '@klarna/bubble-ui'
import { Layout, useResponsiveContext } from '@klarna/mp-ui'
import React from 'react'
import styled from 'styled-components'

import { ApiKeySection } from './ApiKeySection'
import ClientIdSection from './ClientIdSection/ClientIdSection'
import StepConfigureBoostFeatures from './StepConfigureBoostFeatures'
import { StepInstallPlugin } from './StepInstallPlugin'
import StepStartSetup from './StepStartSetup'


const Box = styled.div`
  text-align: center;
`
const StepsContainer = styled.div`
  margin-right: 24px;
`


export default function PluginTabContent () {

  const { isMobile, isTablet } = useResponsiveContext()
  return (
    <Layout.Section>
      <Layout.Column mobileWidth={12} tabletWidth={12} desktopWidth={8} tabletOrder={2} mobileOrder={2} desktopOrder={1}>
        <Box>
          <StepsContainer>
            <StepInstallPlugin />
            <SpacerVertical spaceToken='space/500'/>
            <Separator colorToken='colors/borders/subtle-floating' />

            <SpacerVertical spaceToken='space/500' />
            <StepStartSetup />
            <SpacerVertical spaceToken='space/500' />
            <Separator colorToken='colors/borders/subtle-floating' />

            <SpacerVertical spaceToken='space/500' />
            <StepConfigureBoostFeatures />
            <SpacerVertical spaceToken='space/500' />
            <Separator colorToken='colors/borders/subtle-floating' />
          </StepsContainer>
        </Box>
      </Layout.Column>

      <Layout.Column mobileWidth={12} tabletWidth={12} desktopWidth={4} tabletOrder={1} mobileOrder={1} desktopOrder={2}>
        <Box>
          <Box>
            <ClientIdSection />
          </Box>
          <SpacerVertical spaceToken="space/300" />
          <Box>
            <ApiKeySection />
          </Box>
        </Box>

        {(isMobile || isTablet) && <SpacerVertical spaceToken='space/500' />}
      </Layout.Column>
    </Layout.Section>
  )
}

import insights from 'mage-insights'

import { useApiKey } from '../../hooks/useApiKey'
import { useMid } from '../../hooks/useMid'

export default function useGenerateButton() {
  const {
    areActionsEnabled,
    createApiKey
  } = useApiKey()

  const { selectedMid } = useMid()

  const event = {
    category: 'v1/boost-home/generate-api-key',
    action: 'impression',
    label: selectedMid ?? undefined
  }

  function handleUnlockedClick() {
    insights.event({
      ...event,
      category: event.category + '/unlocked',
      action: 'click'
    })

    createApiKey()
  }

  function handleLockedClick() {
    insights.event({
      ...event,
      category: event.category + '/locked',
      action: 'click'
    })
  }

  return {
    event,
    handleLockedClick,
    handleUnlockedClick,
    areActionsEnabled
  }
}

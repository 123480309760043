import { AppIcon } from 'mage-components'
import { translator } from 'mage-i18n'
import mageInsights from 'mage-insights'
import * as R from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import EmptyOrErrorComponent from './EmptyOrErrorComponent'
import { AcceptInviteModal } from './Modals/AcceptInviteModal'
import { TaskItems } from './TaskItems'
import type { NormalizedTask, SelectorDirectOption, Task } from './types'

export type TasklistProps = {
  raiseAnalyticsEvent: (props: any) => void;
  t: (s: string, o?: { count: number }) => string;
  tasks?: Task[];
}

const EMPTY_ARRAY = []

const ignoredTasks = [
  'home-fe:2fa-config',
  'users-fe:mid-invited'
]

// The tasks will be sorted and displayed according to this order
const appTypePriorityList: string[] = [
  'users-fe:entity-invited',
  'orders-fe:expiring',
  'orders-fe:tocapture',
  'disputes-fe:unresolved',
  'users-fe:mid-added', // TODO: Remove this once we've migrated to entity-added
  'users-fe:entity-added'
]

const getRelativeUrl = (url: string): string => {
  try {
    const parsedUrl = new URL(url)
    const relativeUrl = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash
    return relativeUrl
  } catch (e) {
    return url
  }
}

const generateGetPriority = (priorityList: string[]) => function (appType: string): number {
  return priorityList.includes(appType) ? priorityList.indexOf(appType) : priorityList.length
}

const getPriority = generateGetPriority(appTypePriorityList)

const filterTasks = (tasks: Task[]): Task[] => {
  return tasks.filter(({ appType }) => !ignoredTasks.includes(appType))
}

const normalizeTasks = (tasks: Task[]): NormalizedTask[] => {
  const normalizedTasks: Record<string, NormalizedTask> = {}
  tasks.forEach(task => {
    const { appType, targetId, count = 0, ...partialTask } = task
    if (appType in normalizedTasks) {
      normalizedTasks[appType].count += count
      if (targetId) {
        normalizedTasks[appType].targetIds.push(targetId)
      }
    } else {
      normalizedTasks[appType] = {
        ...partialTask,
        appType,
        count,
        sortIndex: getPriority(appType),
        targetIds: targetId ? [targetId] : []
      }
    }
  })

  return Object.values(normalizedTasks)
    .sort((a, b) => a.sortIndex - b.sortIndex)
}

const generateMapTasksToOptions = t => (tasks: NormalizedTask[]): SelectorDirectOption[] => {
  const options: SelectorDirectOption[] = []
  tasks.forEach(({ appId, appType, count, url }) => {
    const icon = <AppIcon clientId={appId} />
    const label = t?.(`home-fe.tasks.${count > 0 ? 'aggregated' : 'single'}.${appType}`, { count })
    if (label) {
      const value = {
        appType,
        url: getRelativeUrl(url)
      }
      options.push({ icon, label, value })
    }
  })

  return options
}

const TaskList = (props: TasklistProps) => {
  const { raiseAnalyticsEvent, t, tasks = EMPTY_ARRAY } = props
  const [options, setOptions] = useState<SelectorDirectOption[]>([])
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  useEffect(() => {
    const updatedOptions = R.pipe(
      filterTasks,
      normalizeTasks,
      generateMapTasksToOptions(t)
    )(tasks)
    return setOptions(updatedOptions)
  }, [t, tasks])

  const handleSelect = useCallback(({ appType, url }) => {
    if (raiseAnalyticsEvent) {
      raiseAnalyticsEvent({
        category: 'home-fe/tasklist',
        action: 'tasklist-click',
        label: appType
      })
    }

    // Don't reload if url is just a query parameter
    if (/^\/?\?/.test(url)) {
      history.push(url)
    } else {
      window.location.assign(url)
    }
  }, [raiseAnalyticsEvent])

  return (
    <>
      {searchParams.get('task') === 'processStoreInvites' && <AcceptInviteModal />}
      {options.length
        ? (
          <TaskItems onSelect={handleSelect} options={options} />
        )
        : (
          <EmptyOrErrorComponent
            desc={t('home-fe.tasks.empty.desc')}
            isError={false}
            title={t('home-fe.tasks.empty.title')}
          />
        )}
    </>
  )
}

export default R.compose(
  connect(
    (state) => ({
      raiseAnalyticsEvent: mageInsights.event,
      t: translator(state)
    })
  )
)(TaskList)

export {
  generateGetPriority,
  generateMapTasksToOptions,
  getRelativeUrl,
  normalizeTasks,
  TaskList
}

import { DirectSelector } from '@klarna/mp-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import insights, { TrackImpression } from 'mage-insights'
import React from 'react'
import styled from 'styled-components'

import { useMid } from '../../../hooks/useMid'
import { usePlugin } from '../../../hooks/usePlugin'
import { usePluginOptions } from './usePluginOptions'

const FullWidthSelector = styled.div`
  padding-top: 16px;
`

const StyledDirectSelector = styled(DirectSelector)`
  width: 100%;
`

export default function SelectPlatform() {
  const t = i18nHooks.useTranslator()
  const { setPluginId } = usePlugin()
  const { selectedMid } = useMid()

  const pluginOptions = usePluginOptions()

  const dropdownImpressionEvent = {
    category: 'v3/boost-home/install-plugin/dropdown-menu',
    action: 'impression',
    label: selectedMid ?? undefined
  }

  const dropdownClickEvent = {
    category: 'v3/boost-home/install-plugin/dropdown-menu',
    action: 'click',
    label: selectedMid ?? undefined
  }

  const platformClickEvent = (id: string) => ({
    category: `v3/boost-home/install-plugin/${id}`,
    action: 'click',
    label: selectedMid ?? undefined
  })

  const onChangeDropdown = (id: string | string[]): void => {
    if (!setPluginId || typeof id != 'string') return
    setPluginId(id)
    insights.event(platformClickEvent(id))
  }

  const onOpenDropdown = () => {
    insights.event(dropdownClickEvent)
  }

  return (
    <>
      <TrackImpression event={dropdownImpressionEvent}>
        <FullWidthSelector>
          <StyledDirectSelector
            onChange={onChangeDropdown}
            onOpen={onOpenDropdown}
            label={t('home-fe.boosters-home-v03.install-plugin.selectorLabel')}
            options={pluginOptions}
          />
        </FullWidthSelector>
      </TrackImpression>
    </>
  )
}

import { Typography } from '@klarna/bubble-ui'
import Insights from 'mage-insights'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export default ({ href, openInNewWindow = false, viaRouter = false, children, category, underline = true, label = '', colorToken = 'colors/text/accent', ...others }) => {
  const { push } = useHistory()
  const onClick = useCallback(() => {
    Insights.event({
      category: category,
      action: 'click',
      label: label === '' ? undefined : label
    })

    if (viaRouter) {
      push(href)
    } else if (openInNewWindow) {
      window.open(href)
    } else {
      window.location.assign(href)
    }
  }, [push, href, category, viaRouter, openInNewWindow])
  return (
    <a href="#" onClick={onClick} {...others}>
      <Typography textDecorationLine={underline ? 'underline' : 'none'} colorToken={colorToken} textToken='text-style/text/paragraphs/body/regular'>
        {children}
      </Typography>
    </a>
  )
}

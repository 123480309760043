import { ActionIconNewTab, ButtonPrimary, DesignTokens, SpacerVertical, Typography } from '@klarna/bubble-ui'
import Insights from 'mage-insights'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import CardWrapper from '../../../components/AppCard/CardWrapper'
import { BREAKPOINT_PX } from '../../../constants'

type ExternalAppCardProps = {
  buttonText: string;
  clientId: string;
  description: string;
  illustration: React.ReactNode;
  redirectUrl: string;
  title: string;
}

export default function ExternalAppCard ({ buttonText, clientId, description, illustration, redirectUrl, title }: ExternalAppCardProps) {
  const designContext = DesignTokens.useDesignContext()

  const category = `marketing-home/external-app-card/${clientId}`

  useEffect(() => {
    Insights.event({
      category,
      action: 'impression'
    })
  }, [])

  const onClick = () => {
    Insights.event({
      category,
      action: 'click'
    })
    window.open(redirectUrl, '_blank')
  }

  const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid ${designContext.get<DesignTokens.Color>('colors/borders/subtle-floating').toString()};
    border-radius: 16px;
    width: 100%;
    padding: 32px 24px;

    @media (max-width: ${BREAKPOINT_PX.TABLET}px) {
      flex-direction: column;
    }
  `

  const IconContainer = styled.div`
    display: flex;
    align-items: center;
  `

  const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;

    @media (max-width: ${BREAKPOINT_PX.TABLET}px) {
      padding: 24px 0;
    }
  `

  const ActionButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (max-width: ${BREAKPOINT_PX.TABLET}px) {
      margin-left: 0;
    }
  `

  return (
    <>
      <CardWrapper onClick={onClick}>
        <CardContainer>
          <IconContainer>
            {illustration}
          </IconContainer>
          <ContentContainer>
            <Typography textToken='text-style/headings/titles/bold/grande'>{title}</Typography>
            <SpacerVertical spaceToken='space/200' />
            <Typography textToken='text-style/text/paragraphs/default/regular'>{description}</Typography>
          </ContentContainer>
          <ActionButtonContainer>
            <ButtonPrimary
              size='medium'
              icon={ActionIconNewTab}
              iconAlignment='right'
              onClick={onClick}
              onPress={onClick}
            >
              {buttonText}
            </ButtonPrimary>
          </ActionButtonContainer>
        </CardContainer>
      </CardWrapper>
    </>
  )
}

import { TitleSecondary } from '@klarna/bubble-ui'
import { Flex, useResponsiveContext } from '@klarna/mp-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled from 'styled-components'

type BannerCardProps = {
  imageSrc: string;
  titleTranslationKey: string;
}

type FixedHeightTitleContainerProps = {
  isXlDesktop: boolean;
  isDesktop: boolean;
}

const TitleContainer = styled.div`
  max-width: 100%;
  padding: 0;
`

const FixedHeightTitleContainer = styled.div<FixedHeightTitleContainerProps>`
  height: ${(props) => props.isXlDesktop ? '50px' : props.isDesktop ? '60px' : '50px'}
`

const NoWrap = styled.span`
  white-space: nowrap;
`

const spanTransformer = (text: string) => <NoWrap>{text}</NoWrap>

export default function BannerCard({ imageSrc, titleTranslationKey }: BannerCardProps) {
  const { isXLDesktop, isDesktop } = useResponsiveContext()
  const t = i18nHooks.useTranslator()

  return (
    <Flex column style={{ width: '30%', gap: '18px'}}>
      <FixedHeightTitleContainer isXlDesktop={isXLDesktop} isDesktop={isDesktop}>
        <TitleContainer>
          <TitleSecondary>{t(titleTranslationKey, { span: spanTransformer })}</TitleSecondary>
        </TitleContainer>
      </FixedHeightTitleContainer>
      <img src={imageSrc} alt={t(titleTranslationKey, { span: spanTransformer })} width='100%' />
    </Flex>
  )
}

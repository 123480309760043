import { SpacerVertical, Typography } from '@klarna/bubble-ui'
import { Flex, useResponsiveContext } from '@klarna/mp-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import promotionBannerMobile from '../../../../assets/promotion-banner-mobile.png'
import promotionBannerTablet from '../../../../assets/promotion-banner-tablet.png'

function getContainerFlexProps(isMobile: boolean): ComponentProps<typeof Flex> {
  return {
    style: {
      maxWidth: '1132px',
      margin: '0 auto',
      paddingBottom: '20px',
      gap: isMobile ? '40px' : '32px'
    },
    children: null
  }
}

const bannerCardContainerFlexProps: ComponentProps<typeof Flex> = {
  justifyContent: 'center',
  alignItems: 'center',
  style: {
    gap: 'calc(10% / 3)',
    flexWrap: 'nowrap',
    width: '100%'
  },
  children: null
}

const Image = styled.img`
  max-width: 100%;
`

export default function MobileView() {
  const t = i18nHooks.useTranslator()
  const { isMobile } = useResponsiveContext()

  return (
    <Flex {...getContainerFlexProps(isMobile)}>
      <Flex>
        <Typography textToken='text-style/headings/titles/bold/tertiary'>{t('home-fe.boosters-home-v03.promotion-banner.title')}</Typography>
        <SpacerVertical spaceToken={isMobile ? 'space/300' : 'space/200'} />
        <Typography
          textToken='text-style/text/paragraphs/body/regular'
          accessibilityPreset='paragraph'
        >
          {t('home-fe.boosters-home-v03.promotion-banner.subtitle')}
        </Typography>
      </Flex>

      <Flex {...bannerCardContainerFlexProps}>
        <Image src={isMobile ? promotionBannerMobile : promotionBannerTablet} />
      </Flex>
    </Flex>
  )
}

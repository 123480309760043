import { useContext } from 'react'

import { CollapsibleStepsContext } from './context'

export default function useCollapsibleSteps() {
  const value = useContext(CollapsibleStepsContext)

  if (!value) {
    throw new Error('useCollapsibleSteps must be used within a CollapsibleStepsProvider')
  }

  return value
}
import { Credential, useClientKeys, useOrigins } from '@merchant-portal/credentials'
import * as Sentry from '@sentry/react'
import React, { createContext, useContext, useEffect } from 'react'

import { useAccess } from './useAccess'
import { useError } from './useError'
import { useMid } from './useMid'


export const GENERIC_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.default'
export const GENERIC_ERROR_ID = 'default'
export const DATA_FETCH_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.fetch-data'
export const DATA_FETCH_ERROR_ID = 'fetch-data'
export const CREATE_CLIENT_ID_ERROR_KEY = 'home-fe.boosters-plugin.error-messages.create-client-id'
export const CREATE_CLIENT_ID_ERROR_ID = 'create-client-id'


export type ClientId = {
  domainOrigin: string;
  clientIdToken: string;
  areActionsEnabled: boolean;
  isGenerateButtonVisible: boolean;
  isAddOriginModalVisible: boolean;
  isLoading: boolean;
  openAddOriginModal: () => void;
  onAddOriginSuccess: () => Promise<void>;
  setIsAddOriginModalVisible: (isVisible: boolean) => void;
}

type ClientIdProviderProps = {
  children: React.JSX.Element;
}

export const ClientIdContext = createContext<ClientId | undefined>(undefined)

export const ClientIdProvider = ({
  children
}: ClientIdProviderProps): React.JSX.Element | null => {

  const { selectedMid } = useMid()
  const { setErrorToTranslate } = useError()
  const { canCreateClientId } = useAccess()


  const [domainOrigin, setDomainOrigin] = React.useState<string>('')
  const [clientIdToken, setClientIdToken] = React.useState<string>('')
  const [mustRefetchData, setMustRefetchData] = React.useState<boolean>(false)
  const [isAddOriginModalVisible, setIsAddOriginModalVisible] = React.useState<boolean>(false)
  const [isLoading, setIsloading] = React.useState<boolean>(false)

  const isGenerateButtonVisible = !domainOrigin && !clientIdToken
  const areActionsEnabled = !!selectedMid && canCreateClientId

  let createFirstClientId: () => Promise<string>

  const { getOrigins } = useOrigins({
    mid: selectedMid || '',
    fetchingOriginsInitially: false
  })

  const { getClientKeys, createClientKey } = useClientKeys({
    mid: selectedMid || '',
    fetchingClientKeysInitially: false
  })

  if (selectedMid) {
    createFirstClientId = createClientKey

    const fetchData = async () => {
      try {
        startLoading()
        const origins = await getOrigins()
        const clientIds = await getClientKeys()

        setDomainOrigin(getFirstOrigin(origins))
        setClientIdToken(getFirstKeyToken(clientIds))
      } catch (error: any) {
        handleFetchDataError(error)
      } finally {
        setIsloading(false)
      }
    }
    useEffect(() => {
      fetchData()
    }, [selectedMid, mustRefetchData])
  }

  function startLoading () {
    setDomainOrigin('')
    setClientIdToken('')
    setIsloading(true)
  }

  const handleFetchDataError = (error: any) => {
    const sentryErrorId = Sentry.captureException(error)
    setErrorToTranslate(
      DATA_FETCH_ERROR_KEY,
      DATA_FETCH_ERROR_ID,
      sentryErrorId
    )
  }

  const onAddOriginSuccess = async () => {
    setIsAddOriginModalVisible(false)
    if (!createFirstClientId) {
      setErrorToTranslate(GENERIC_ERROR_KEY, GENERIC_ERROR_ID)
      return
    }

    await tryToCreateFirstClientId()

    setMustRefetchData(true)
  }

  const tryToCreateFirstClientId = async () => {
    try {
      setIsloading(true)
      await createFirstClientId()
    } catch (error: any) {
      handleCreateClientIdError(error)
    } finally {
      setIsloading(false)
    }
  }

  const handleCreateClientIdError = (error: any) => {
    const sentryErrorId = Sentry.captureException(error)
    setErrorToTranslate(
      CREATE_CLIENT_ID_ERROR_KEY,
      CREATE_CLIENT_ID_ERROR_ID,
      sentryErrorId
    )
  }

  const openAddOriginModal = () => {
    setIsAddOriginModalVisible(true)
  }

  const accessController: ClientId = {
    domainOrigin,
    clientIdToken,
    areActionsEnabled,
    isGenerateButtonVisible,
    isAddOriginModalVisible,
    isLoading,
    openAddOriginModal,
    onAddOriginSuccess,
    setIsAddOriginModalVisible
  }

  return (
    <ClientIdContext.Provider value={accessController}>{children}</ClientIdContext.Provider>
  )
}

const getFirstOrigin = (origins: string[]): string => {
  if (origins.length === 0) return ''
  return origins[0]
}

const getFirstKeyToken = (keys: Credential[]): string => {
  if (keys.length === 0) return ''
  return keys[0].clientToken || ''
}

export const useClientId = (): ClientId => {
  const value = useContext(ClientIdContext)

  if (!value) throw new Error('cannot get ClientIdContext as not been provided')

  return value
}

import type { AlertBlockProps } from '@bubble-ui/molecule-alert-block/types/types'
import { AlertBlock, Link, Typography } from '@klarna/bubble-ui'
import { hooks as coreHooks } from 'mage-core'
import * as R from 'ramda'
import React, { type ComponentType, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import * as selectors from '../../../selectors'

interface Notification {
  title?: string;
  content: string;
  link: string;
  linkLabel: string;
  Icon: ComponentType;
  status?: AlertBlockProps['status'];
}

interface NotificationBanner {
  notification: Notification;
}

export function NotificationBanner ({ notification }: NotificationBanner) {
  return (
    <AlertBlock icon={<notification.Icon />} title={notification.title} status={notification.status}>
      <Typography>{notification.content}</Typography>
      <Link onPress={() => {
        window.location.href = notification.link
      }} textToken="text-style/text/labels/body/bold">
        {notification.linkLabel}
      </Link>
    </AlertBlock>
  )
}

interface AnimatedNotificationBanners {
  notifications: Notification[];
  delay?: number;
}

const AnimatedNotificationBanners = ({ notifications, delay = 10000 }: AnimatedNotificationBanners) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalID = setInterval(() => {
      setIndex(R.mathMod(index + 1, notifications.length))
    }, delay)

    return () => {
      clearInterval(intervalID)
    }
  }, [notifications, index])

  return <NotificationBanner notification={notifications[index]} />
}

const NotificationBannerWrapper = () => {
  const tokenHelper = coreHooks.useTokenHelper()
  const getNotifications = useSelector(selectors.generateGetNotifications)

  if (!tokenHelper) {
    return null
  }
  const notifications = getNotifications(tokenHelper)

  if (!notifications.length) {
    return null
  } else if (notifications.length === 1) {
    return <NotificationBanner notification={notifications[0]} />
  } else {
    return <AnimatedNotificationBanners notifications={notifications} />
  }
}

export default NotificationBannerWrapper

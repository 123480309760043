import { Typography } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'
import styled from 'styled-components'

import { useCollapsibleSteps } from '../../hooks/useCollapsibleSteps'
import { usePlugin } from '../../hooks/usePlugin'
import { CollapsibleStep } from '../CollapsibleStep'
import GetPluginAndKlarnaDocsLink from './GetPluginAndKlarnaDocsLink'
import { SelectPlatform } from './SelectPlatform'

const InnerContainer = styled.div`
  text-align: left;
`

export default function StepInstallPlugin() {
  const t = i18nHooks.useTranslator()
  const { pluginId } = usePlugin()
  const { isFirstStepOpen, toggleFirstStep } = useCollapsibleSteps()

  return (
    <CollapsibleStep
      index={t('home-fe.boosters-home-v03.install-plugin.stepNumber')}
      title={t('home-fe.boosters-home-v03.install-plugin.title')}
      onClick={toggleFirstStep}
      isOpen={isFirstStepOpen}
    >
      <InnerContainer>
        <Typography textToken="text-style/text/paragraphs/body/regular">
          {t('home-fe.boosters-home-v03.install-plugin.description')}
        </Typography>
      </InnerContainer>
      <SelectPlatform />
      {pluginId && (<GetPluginAndKlarnaDocsLink />)}
    </CollapsibleStep>
  )
}

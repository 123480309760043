import renderApp from 'mage-app'

import translations from '../translations'
import createReducers from './reducers'
import Routes from './Routes'

renderApp(Routes, {
  createReducers,
  translations
})

import { ActionIconArrowRight, Link, SpacerVertical, Typography } from '@klarna/bubble-ui'
import Insights, { TrackImpression } from 'mage-insights'
import React from 'react'
import styled from 'styled-components'

import CardWrapper from '../../../../../components/AppCard/CardWrapper'

const TopAlignment = styled.div`
  height: 100%;
`

type ImageProps = {
  imageUrl: string;
}

const Image = styled.div<ImageProps>`
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom center;
  border-radius: var(--corner-radius-m, 16px);
  max-height: 160px;
  min-height: 160px;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 45.28%;
  }
`

const CallToActionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

type AppCardProps = ImageProps & {
  description: string;
  clientId: string;
  url: string;
  buttonLabel: string;
  componentRef?: React.RefObject<HTMLDivElement>;
}

function AppCard ({ imageUrl, description, clientId, url, buttonLabel, componentRef }: AppCardProps) {
  const category = `home-fe/v3/boost-home/install-websdk/${clientId}`
  const impressionEvent = {
    category,
    action: 'impression'
  }

  const onClick = () => {
    Insights.event({
      category,
      action: 'click'
    })
    location.href = url
  }

  return (
    <div ref={componentRef}>
      <TrackImpression event={impressionEvent}>
        <CardWrapper onClick={onClick}>
          <TopAlignment>
            <Image imageUrl={imageUrl} />
            <SpacerVertical spaceToken='space/200' />
            <Typography
              textToken='text-style/text/paragraphs/default/regular'
              textAlignToken='text-alignment/start'
              accessibilityPreset='paragraph'
            >
              {description}
            </Typography>
            <SpacerVertical spaceToken='space/100' />
            <CallToActionWrapper>
              <Link onPress={onClick} isUnderlined>
                {buttonLabel}
              </Link>
              <ActionIconArrowRight />
            </CallToActionWrapper>
          </TopAlignment>
        </CardWrapper>
      </TrackImpression>
    </div>
  )
}

export default AppCard

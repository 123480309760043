import styled from 'styled-components'

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
import { selectors as experimentationSelectors } from '@merchant-portal/experimentation'
import { hooks as coreHooks } from 'mage-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { useHasPartnerAccount } from './hooks/useHasPartnerAccount'
import {
  BoostHome,
  MarketingHome,
  StoreHome
} from './pages'
import HomeInstallationGuide from './pages/BoostHome/pages/HomeInstallationGuide'

const BOOST_V03_EXPERIMENT_KEY = 'merchant-portal.boost.v03.enabled'

function Routes () {
  const { path } = coreHooks.useSanitizedRouteMatch()

  const hasPartnerAccount = useHasPartnerAccount()

  const isBoostDashboardEnabled = useSelector(
    experimentationSelectors.isFeatureToggleEnabled(BOOST_V03_EXPERIMENT_KEY)
  )

  const mustDisplayBoostDashboard
    = hasPartnerAccount || isBoostDashboardEnabled

  return (
    <Switch>
      {mustDisplayBoostDashboard ?
        <Route from={`${path}/boost`} component={HomeInstallationGuide} /> :
        <Route from={`${path}/boost`} component={BoostHome} />
      }

      <Route from={`${path}/growth`} component={MarketingHome} />
      <Route from={`${path}/`} component={StoreHome} />
    </Switch>
  )
}

export default Routes

import * as Sentry from '@sentry/react';
import moment from 'moment-timezone';
import { filter, flatten, map, pipe, prop, propSatisfies } from 'ramda';
export const CONSTANTS = {
    CURRENCIES: {
        AUD: 'AUD',
        CAD: 'CAD',
        CHF: 'CHF',
        DKK: 'DKK',
        EUR: 'EUR',
        GBP: 'GBP',
        NOK: 'NOK',
        NZD: 'NZD',
        PLN: 'PLN',
        RON: 'RON',
        SEK: 'SEK',
        USD: 'USD'
    }
};
export const getTimezone = () => moment.tz.guess();
export const getDefaultCurrencyForTimezone = ({ timezone }) => {
    if (moment.tz.zonesForCountry('US').includes(timezone)) {
        return CONSTANTS.CURRENCIES.USD;
    }
    if (moment.tz.zonesForCountry('CA').includes(timezone)) {
        return CONSTANTS.CURRENCIES.CAD;
    }
    if (moment.tz.zonesForCountry('AU').includes(timezone)) {
        return CONSTANTS.CURRENCIES.AUD;
    }
    if (moment.tz.zonesForCountry('SE').includes(timezone)) {
        return CONSTANTS.CURRENCIES.SEK;
    }
    if (moment.tz.zonesForCountry('NO').includes(timezone)) {
        return CONSTANTS.CURRENCIES.NOK;
    }
    if (moment.tz.zonesForCountry('DK').includes(timezone)) {
        return CONSTANTS.CURRENCIES.DKK;
    }
    if (moment.tz.zonesForCountry('GB').includes(timezone)) {
        return CONSTANTS.CURRENCIES.GBP;
    }
    if (moment.tz.zonesForCountry('CH').includes(timezone)) {
        return CONSTANTS.CURRENCIES.CHF;
    }
    if (moment.tz.zonesForCountry('PL').includes(timezone)) {
        return CONSTANTS.CURRENCIES.PLN;
    }
    if (moment.tz.zonesForCountry('NZ').includes(timezone)) {
        return CONSTANTS.CURRENCIES.NZD;
    }
    if (moment.tz.zonesForCountry('RO').includes(timezone)) {
        return CONSTANTS.CURRENCIES.RON;
    }
    return CONSTANTS.CURRENCIES.EUR;
};
export const getLatestOrderDatesFromMetadata = ({ merchantData, selectedMids }) => pipe(filter(propSatisfies(mid => selectedMids.includes(mid), 'merchantId')), map(prop('transactingRegions')), flatten, map(prop('latestOrderDate')))(merchantData);
const getMoment = date => {
    if (!date || !moment(date).isValid()) {
        Sentry.withScope(scope => {
            scope.setTag('team', 'mpux');
            scope.setExtras(date);
            Sentry.captureException('Error in getMoment: Invalid date provided');
        });
    }
    return moment(date);
};
// Get elapsed time on past dates
const getElapsedTime = date => {
    const now = moment();
    const momentDate = getMoment(date);
    if (momentDate.isAfter(now, 'milliseconds')) {
        Sentry.withScope(scope => {
            scope.setTag('team', 'mpux');
            scope.setExtras({ date: momentDate.toISOString() });
            Sentry.captureException('Error in getElapsedTime: Future date provided');
        });
        return null;
    }
    const elapsedHours = now.diff(momentDate, 'hours');
    const elapsedMinutes = now.diff(momentDate, 'minutes') - elapsedHours * 60;
    return {
        elapsedHours,
        elapsedMinutes
    };
};
const getShortestElapsedTime = dates => {
    if (!dates || !Array.isArray(dates) || !dates.length) {
        Sentry.withScope(scope => {
            scope.setTag('team', 'mpux');
            scope.setExtras({ dates });
            Sentry.captureException('Error in getShortestElapsedTime: Array not provided');
        });
        return null;
    }
    const maxDate = moment.max(dates.map(getMoment));
    return getElapsedTime(maxDate);
};
const getStartOfDay = date => getMoment(date).startOf('day').startOf('hour').startOf('minute').startOf('second').format();
const getEndOfDay = date => getMoment(date).endOf('day').endOf('hour').endOf('minute').endOf('second').format();
const getPrevDay = date => getStartOfDay(getMoment(date).subtract(1, 'day'));
const getNextDay = date => getStartOfDay(getMoment(date).add(1, 'day'));
const getDayOfWeek = date => getMoment(date).isoWeekday();
const isTodayOrAfter = date => getMoment(date).isSameOrAfter(moment(), 'day');
const isYesterday = date => getMoment(date).isSame(moment().subtract(1, 'day'), 'day');
const isOneWeekAgoOrBefore = date => getMoment(date).isSameOrBefore(moment().subtract(1, 'week'), 'day');
export const insightsDateUtils = {
    getShortestElapsedTime,
    getStartOfDay,
    getElapsedTime,
    getEndOfDay,
    getPrevDay,
    getNextDay,
    getDayOfWeek,
    isTodayOrAfter,
    isYesterday,
    isOneWeekAgoOrBefore
};

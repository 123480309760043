import { SpacerVertical } from '@klarna/bubble-ui'
import { ClientKeyField } from '@merchant-portal/credentials'
import { hooks as i18nHooks } from 'mage-i18n'
import { TrackImpression } from 'mage-insights'
import React from 'react'

import TrackableLink from '../../../../../../components/TrackableLink'
import { useClientId } from '../../hooks/useClientId'
import { useMid } from '../../hooks/useMid'

export default function ManageClientId() {
  const { clientIdToken } = useClientId()
  const { selectedMid } = useMid()
  const t = i18nHooks.useTranslator()

  const event = {
    category: 'v3/boost-home/authenticate-and-connect/manage-client-id',
    action: 'impression',
    label: selectedMid ?? undefined
  }

  return (
    <TrackImpression event={event}>
      <ClientKeyField clientKey={clientIdToken} />

      <SpacerVertical spaceToken='space/200' />

      <TrackableLink
        href='/settings/client-identifier'
        category='v3/boost-home/authenticate-and-connect/manage-client-id'
        colorToken='colors/text/default'
      >
        {t('home-fe.boosters-home-v03.client-identifier-section.manage-link')}
      </TrackableLink>
    </TrackImpression>
  )
}

import * as Sentry from '@sentry/react'

import type { Task } from './types'

function createTask (countData, appId, type, url) {
  const count = parseInt(countData)

  if (!count) return

  return {
    appType: appId + ':' + type,
    appId,
    type,
    url,
    count
  }
}

const createFetchers = clients => {
  async function userTasks (userId, userRegion): Promise<Task[]> {
    const { data } = await clients.notifier.get(`/v1/users/${userId}/tasks?category=todo`, { region: userRegion })
    return data
  }

  async function unfulfilledOrders (): Promise<Task | undefined> {
    try {
      const resp = await clients.orders.get('/count/unfulfilled')
      return createTask(resp.data, 'orders-fe', 'tocapture', '/orders/to-capture#realm=merchants')
    } catch (err) {
      // Catch error so that if this fails the task list
      // will still display the normal tasks
      Sentry.captureException(err)
    }
  }

  async function expiringSoonOrders (): Promise<Task | undefined> {
    try {
      const resp = await clients.orders.get('/count/expiring-soon')
      return createTask(resp.data, 'orders-fe', 'expiring', '/orders/expires-soon#realm=merchants')
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  return {
    userTasks,
    unfulfilledOrders,
    expiringSoonOrders
  }
}

export default function createRequests (clients, userId, userRegion, { includeOrders = false } = {}) {
  const fetch = createFetchers(clients)

  const requests: Promise<Task[] | Task | undefined>[] = [fetch.userTasks(userId, userRegion)]

  if (includeOrders) {
    requests.push(fetch.expiringSoonOrders(), fetch.unfulfilledOrders())
  }

  return requests
}

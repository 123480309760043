import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { Task } from './types'

export interface CounterState {
  tasks: Task[];
  isError: boolean;
  isFetching: boolean;
}

const initialState: CounterState = {
  tasks: [],
  isError: false,
  isFetching: false
}

export default createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    init: state => {
      state.isFetching = true
      state.isError = false
    },
    success: (state, action: PayloadAction<Task[]>) => {
      state.isFetching = false
      state.isError = false
      state.tasks = action.payload
    },
    failure: state => {
      state.isFetching = false
      state.isError = true
    }
  }
})

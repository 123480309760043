import { SpacerVertical, Typography } from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import React from 'react'

import AppCard from '../../../components/AppCard'
import CardContainer from '../../../components/AppCard/CardContainer'
import { B2B_COLORS } from '../../../constants'
import useExternalAccessibleApps from '../../../hooks/useExternalAccessibleApps'
import useUserAccessibleApps from '../../../hooks/useUserAccessibleApps'
import searchCompareImage from '../assets/search-compare-cover.png'

function AppList () {
  const t = i18nHooks.useTranslator()
  const componentRef = React.useRef<HTMLDivElement>(null)

  const userExternalApps = useExternalAccessibleApps()
  const hasAccessToExternalApps = userExternalApps.length > 0

  const availableApps = {
    'klarna-search-b2b-portal': {
      backgroundColor: B2B_COLORS.Vanilla,
      imageUrl: searchCompareImage
    }
  }

  const userAccessibleApps = useUserAccessibleApps(Object.keys(availableApps))

  const apps = userAccessibleApps.map(app => ({
    title: t(`core.apps.${app.clientId}.title`),
    description: t(`home-fe.marketing-home.essentials.${app.clientId}.description`),
    clientId: app.clientId,
    url: app.url,
    ...availableApps[app.clientId]
  }))

  if (apps.length === 0) {
    return null
  }

  return (
    <>
      {hasAccessToExternalApps && (
        <>
          <Typography textToken='text-style/headings/titles/bold/tertiary'>{t('home-fe.marketing-home.essentials.title')}</Typography>
          <SpacerVertical spaceToken='space/300' />
        </>
      )}
      <CardContainer>
        {apps.map(app => (
          <AppCard
            key={app.clientId}
            title={app.title}
            description={app.description}
            imageUrl={app.imageUrl}
            backgroundColor={app.backgroundColor}
            clientId={app.clientId}
            url={app.url}
            buttonLabel={t('home-fe.marketing-home.essentials.cta-label')}
            componentRef={componentRef}
          />)
        )}
      </CardContainer>
    </>
  )
}

export default AppList

import { useContext } from 'react'

import { ShowMidSelector, ShowMidSelectorContext } from './context'

export const useShowMidSelector = (): ShowMidSelector => {
  const value = useContext(ShowMidSelectorContext)

  if (!value) throw new Error('cannot get ShowMidSelectorContext as not been provided')

  return value
}